<template>
  <ValidationObserver ref="data_form">
    <md-button class="md-default" @click="onBack()" v-if="tab_index !== '0'"
      >返回</md-button
    >
    <form @submit.prevent="validate">
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item  md-size-25 md-small-size-100">
              <md-field>
                <label>UUID</label>
                <md-input v-model="model.sensor_uuid" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-25 md-small-size-100">
              <md-field>
                <label>Log ID</label>
                <md-input v-model="model.id" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-25 md-small-size-100">
              <md-field>
                <label>Date</label>
                <md-input v-model="model.created_at" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-25 md-small-size-100">
              <md-field>
                <label>App 版本</label>
                <md-input v-model="model.app_version" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-25 md-small-size-100">
              <md-field>
                <label>溫度</label>
                <md-input v-model="model.temperature" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-25 md-small-size-100">
              <md-field>
                <label>濕度</label>
                <md-input v-model="model.humidity" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-25 md-small-size-100">
              <md-field>
                <label>基準線</label>
                <md-input
                  v-model="model.baseline_thresholds"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-25 md-small-size-100">
              <md-field>
                <label>電池電壓</label>
                <md-input
                  v-model="model.battery_voltage"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-20 md-small-size-100">
              <md-field>
                <label>Owner</label>
                <md-input v-model="model.patient_name" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-20 md-small-size-50">
              <md-field>
                <label>性別</label>
                <md-icon>{{
                  model.gender === "F" ? "female" : "male"
                }}</md-icon>
                <md-input v-model="model.gender" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-20 md-small-size-50">
              <md-field>
                <label>月齡</label>
                <md-input v-model="model.age" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-20 md-small-size-50">
              <md-field>
                <label>事件</label>
                <md-input v-model="model.event_type" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-20 md-small-size-50">
              <md-field>
                <label>OS</label>
                <md-icon>{{
                  model.os === "and" ? "android" : "apple"
                }}</md-icon>
                <md-input v-model="model.os" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-20 md-small-size-50">
              <md-field>
                <label>國家</label>
                <md-input
                  v-model="model.location_country"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-20 md-small-size-50">
              <md-field>
                <label>城市</label>
                <md-input v-model="model.location_city" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-20 md-small-size-50">
              <md-field>
                <label>Region</label>
                <md-input
                  v-model="model.location_region"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div
              class="md-layout-item  md-size-20 md-small-size-50"
              v-if="model.level_settings_version"
            >
              <md-field>
                <label>靈敏度</label>
                <md-input
                  v-model="model.level_settings_version.lateEarlyCount"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div
              class="md-layout-item  md-size-20 md-small-size-50"
              v-if="model.level_settings_version"
            >
              <md-field>
                <label>之前靈敏度</label>
                <md-input
                  v-model="model.level_settings_version.lateEarlyCountUSED"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-100">
              <md-field>
                <label>附註</label>
                <md-input v-model="model.comment" type="text"></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import Swal from "sweetalert2";
import request from "@/utils/requestGolang";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { localize } from "vee-validate";
import zh_TW from "vee-validate/dist/locale/zh_TW.json";

localize("zh_TW", zh_TW);
extend("required", required);
extend("email", email);

export default {
  components: {},
  created: async function() {
    this.isLoading = false;
    if (this.select_model) this.model = this.select_model;
  },

  data: function() {
    let now = new Date();
    return {
      url_name: "baby/list",
      //permission_name: "deviceinfo",
      model: {
        created_at: "",
        id: "",
        updated_at: "",
        addedAt: "",
        age: 0,
        app_version: "",
        baseline_thresholds: "",
        battery_voltage: 0,
        comment: "",
        event_type: 0,
        gender: "M",
        humidity: 0,
        level_settings_version: {
          lateEarlyCount: 3,
          originalLevelSettings: [
            {
              humidity: 88.7027,
              temperature: 0
            },
            {
              humidity: 100.5002,
              temperature: 0
            },
            {
              humidity: 105.0683,
              temperature: 0
            },
            {
              humidity: 109.6365,
              temperature: 0
            }
          ],
          settings: [
            {
              humidity: 88.7027,
              temperature: 0
            },
            {
              humidity: 100.5002,
              temperature: 0
            },
            {
              humidity: 105.0683,
              temperature: 0
            },
            {
              humidity: 109.6365,
              temperature: 0
            }
          ],
          lateEarlyCountUSED: 0,
          algorithm: ""
        },
        location_city: "",
        location_country: "",
        location_region: "",
        madeAt: "",
        os: "ios",
        patient_name: "",
        sensor_uuid: "",
        temperature: 0
      }
    };
  },
  props: {
    select_model: Object,
    tab_index: {
      type: String,
      default: "0"
    }
  },

  computed: {
    isNew() {
      return this.model.device_id.length === 0;
    }
  },
  methods: {
    validate() {
      return this.$refs.data_form.validate().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
    onBack() {
      this.$emit("on-back", this.tab_index);
    }
  },
  mounted() {}
};
</script>

<style lang="css" scoped>
.error.md-icon {
  right: 20px;
}
.success.md-icon {
  right: 20px;
}
</style>
