<template>
  <div class="md-layout-item md-size-30 md-small-size-70">
    <md-icon>{{ icons }}</md-icon>
    <label style="font-size: 30 px">{{ displayText }}</label>
  </div>
</template>
<script>
export default {
  name: "Waiting-Response",
  data() {
    return {
      originText: "",
      displayText: "",
      tick: 0,
      beBreak: false,
      icons: "hourglass_empty",
    };
  },
  props: {
    msg: {
      type: String,
      default: "等待回應中",
    },
  },
  created: async function () {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    do {
      if (this.tick % 7 == 0) {
        //this.displayText = this.originText;
        this.displayText = this.msg;
      } else {
        this.displayText = this.displayText + ".";
      }
      switch (this.tick % 4) {
        case 0:
          this.icons = "hourglass_empty";
          break;
        case 1:
          this.icons = "hourglass_top";
          break;
        case 2:
          this.icons = "hourglass_full";
          break;
        default:
          this.icons = "hourglass_bottom";
          break;
      }
      this.tick++;
      await sleep(1000);
    } while (!this.beBreak);
  },
  mounted() {},
  unmounted() {
    this.beBreak = true;
  },
};
</script>
