<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header>
          <div class="md-layout">
            <div class="md-layout-item md-size-20 md-small-size-40">
              <md-checkbox v-model="iOS_only" @change="osFilterSelect('ios')"
                >Show iOS Only</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-20 md-small-size-40">
              <md-checkbox
                v-model="android_only"
                @change="osFilterSelect('android')"
                >Show Android Only</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-60 md-small-size-20"></div>
            <div class="md-layout-item md-size-30 md-small-size-70">
              <md-autocomplete
                class="search"
                v-model="to_page_str"
                :md-options="search_options"
              >
                <label>跳至指定頁面</label>
                <template slot="md-autocomplete-item" slot-scope="{ item }">{{
                  item
                }}</template>
              </md-autocomplete>
            </div>
            <div class="md-layout-item md-size-15">
              <md-button class="md-info" @click="onJump"
                ><md-icon>move_down</md-icon>跳往</md-button
              >
            </div>
            <div class="md-layout-item md-size-30 md-small-size-70">
              <md-autocomplete
                class="search"
                v-model="mac"
                :md-options="search_options"
              >
                <label>UUID</label>
                <template slot="md-autocomplete-item" slot-scope="{ item }">{{
                  item
                }}</template>
              </md-autocomplete>
            </div>
            <div class="md-layout-item md-size-15">
              <md-button class="md-success" @click="onSearch"
                ><md-icon>search</md-icon>搜尋</md-button
              >
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <waiting-response
            v-if="is_busy"
            msg="等待資料庫回應中，過久請重新整理"
          ></waiting-response>
          <md-table
            :value="searchedData"
            @md-selected="onSelected"
            class="paginated-table table-striped table-hover"
            v-else
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="single"
            >
              <md-table-cell md-label="UUID">
                {{ item.sensor_uuid }}
              </md-table-cell>
              <md-table-cell md-label="Owner">
                {{ item.patient_name }}
              </md-table-cell>
              <md-table-cell md-label="性別">
                <md-icon>{{ item.gender === "F" ? "female" : "male" }}</md-icon>
              </md-table-cell>
              <md-table-cell md-label="月齡">
                {{ item.age }}
              </md-table-cell>
              <md-table-cell md-label="事件">
                {{ item.event_type }}
              </md-table-cell>
              <md-table-cell md-label="OS">
                <md-icon>{{ item.os === "and" ? "android" : "apple" }}</md-icon>
              </md-table-cell>
              <md-table-cell md-label="Date">
                {{ item.created_at }}
              </md-table-cell>
              <md-table-cell md-label="Country">
                {{ item.location_country }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              第 {{ from + 1 }} 到 {{ to }} 項目 於 {{ total }} 表單
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :pageCount="total"
          ></pagination>
        </md-card-actions>
        <md-card-actions> </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination } from "@/components";
import request from "@/utils/requestGolang";
import WaitingResponse from "@/components/WaitingResponse.vue";
//import moment from "moment";

export default {
  components: {
    Pagination,
    WaitingResponse,
  },
  created: async function () {
    this.requestData();
    this.selected = null;
  },
  data: function () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      search_options: [],
      search_model: null,
      mac: "",
      iOS_only: false,
      android_only: false,
      selected: null,
      searchedData: [
        {
          created_at: "",
          id: "",
          updated_at: "",
          addedAt: "",
          age: 0,
          app_version: "",
          baseline_thresholds: "",
          battery_voltage: 0,
          comment: "",
          event_type: 0,
          gender: "M",
          humidity: 0,
          level_settings_version: {
            lateEarlyCount: 3,
            originalLevelSettings: [
              {
                humidity: 88.7027,
                temperature: 0,
              },
              {
                humidity: 100.5002,
                temperature: 0,
              },
              {
                humidity: 105.0683,
                temperature: 0,
              },
              {
                humidity: 109.6365,
                temperature: 0,
              },
            ],
            settings: [
              {
                humidity: 88.7027,
                temperature: 0,
              },
              {
                humidity: 100.5002,
                temperature: 0,
              },
              {
                humidity: 105.0683,
                temperature: 0,
              },
              {
                humidity: 109.6365,
                temperature: 0,
              },
            ],
            lateEarlyCountUSED: -1,
            algorithm: "",
          },
          location_city: "",
          location_country: "",
          location_region: "",
          madeAt: "",
          os: "ios",
          patient_name: "",
          sensor_uuid: "",
          temperature: 0,
        },
      ],
      is_busy: true,
      to_page_str: "",
      url_name: "baby/list",
    };
  },
  props: {
    filter_query: {
      type: String,
      default: "",
    },
    url_path: {
      type: String,
      default: "",
    },
    tab_index: {
      type: String,
      default: "0",
    },
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    query_url: function () {
      let searchParams = new URLSearchParams();

      if (this.pagination.currentPage) {
        searchParams.append("page", this.pagination.currentPage);
      }
      if (this.search_model) {
        searchParams.append("search", this.search_model);
      }
      if (this.iOS_only) {
        searchParams.append("os", "ios");
      }
      if (this.android_only) {
        searchParams.append("os", "and");
      }
      let url = `/${this.url_name}?${searchParams.toString()}`;

      return url;
    },
  },

  methods: {
    osFilterSelect(selected) {
      if (selected == "ios") {
        this.android_only = false;
      } else {
        this.iOS_only = false;
      }
    },
    async onJump() {
      let p = parseInt(this.to_page_str);
      if (p != 0) {
        this.pagination.currentPage = p;
      }
    },
    async onSearch() {
      this.search_model = this.mac;
      this.pagination.currentPage = 1;
      this.to_page_str = "";
    },
    async requestData() {
      this.is_busy = true;
      let { data } = await request.get(this.query_url);
      this.searchedData = data.data;
      this.pagination.total = data.total;
      this.pagination.perPage = data.per_page;
      this.pagination.currentPage = data.current_page;
      this.is_busy = false;
    },
    onSelected(item) {
      this.selected = item;
      if (this.tab_index != 4)
        this.$emit("on-selected", this.tab_index, this.selected);
    },
    showBrandName(item) {
      let brand = this.brand_list.find((i) => i.brand_id == item);
      return brand ? brand.display_name : "";
    },
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    },
    search_model: async function (newData, oldData) {
      if (newData != null) {
        try {
          this.is_busy = true;
          let { data } = await request.get(
            `/${this.url_name}?search=${newData}`
          );
          this.searchedData = data.data;
          this.pagination.total = data.total;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
          this.is_busy = false;
        } catch (error) {
          console.log(error);
          this.is_busy = false;
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-table-head {
  background-color: lightgray !important;
}
</style>
