<template>
  <div class="md-layout text-center">
    <notifications></notifications>
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <login-card header-color="green" v-if="isReady">
        <h3 slot="title" class="title">CviCloud</h3>
        <h4 slot="buttons" class="title"></h4>

        <md-field class="md-form-group" slot="inputs">
          <md-icon>face</md-icon>
          <label>帳號</label>
          <md-input v-model="model.email" type="text"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>密碼</label>
          <md-input
            v-model="model.password"
            type="password"
            v-on:keyup.enter="onLogin"
          ></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>face</md-icon>
          <label for="select">廠牌</label>
          <md-select
            v-model="model.brand_id"
            name=" "
            style="padding-left: 10px"
          >
            <md-option
              v-for="item in brand_list"
              :key="item.brand_id"
              :value="item.brand_id"
              >{{ item.display_name }}</md-option
            >
          </md-select>
        </md-field>
        <md-button
          slot="footer"
          class="md-simple md-success md-lg"
          @click="onLogin"
          >登入</md-button
        >
      </login-card>
      <div class="text-left text-muted mt-4">
        <small
          >This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a>
          and
          <a href="https://policies.google.com/terms">Terms of Service</a>
          apply.</small
        >
      </div>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import requestGolang from "@/utils/requestGolang";
import hasPerm from "@/utils/permission";
import { perm } from "@/utils/permission";
export default {
  components: {
    LoginCard,
  },
  data() {
    const isDevelopment = process.env.NODE_ENV === "development";

    let defaultData;
    if (isDevelopment) {
      defaultData = {
        model: {
          email: process.env.VUE_APP_USER,
          password: process.env.VUE_APP_PASSWORD,
          grant_type: "password",
          brand_id: null,
          response: "",
        },
        brand_list: [],
        isReady: false,
      };
    } else {
      defaultData = {
        model: {
          email: null,
          password: null,
          grant_type: "password",
          brand_id: null,
          response: "",
        },
        brand_list: [],
        isReady: false,
      };
    }
    return defaultData;
  },
  created: async function () {
    try {
      if (!this.$store.state.golangToken) {
        let { data } = await requestGolang.get("/Gweb/brand_all", {
          headers: {
            Authorization: process.env.VUE_APP_AUTHORIZATIOH,
          },
        });
        this.brand_list = data;
        this.isReady = true;
        return;
      }
      this.goDashboard();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    onLogin() {
      this.onGolangLogin();
    },
    async goDashboard() {
      {
        let { data } = await requestGolang.get("/Gweb/user/info");
        if (!data.is_staff && !data.is_super_user) {
          this.$notify({
            timeout: 2500,
            message: "登入失敗",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning",
          });
          return;
        }
        {
          let { data } = await requestGolang.get("/Gweb/brand_all", {
            headers: {
              Authorization: process.env.VUE_APP_AUTHORIZATIOH,
            },
          });
          localStorage.setItem("brand", JSON.stringify(data));
          this.$store.state.brand = data;
        }
        let brand = this.brand_list.find((i) => i.brand_id == data.brand_id);
        data["brand_name"] = brand ? brand.display_name : "";
        this.$store.state.info = data;
        localStorage.setItem("info", JSON.stringify(data));
        if (!hasPerm(data, perm.ViewBrand)) {
          localStorage.setItem("brand", JSON.stringify([brand]));
          this.$store.state.brand = [brand];
        }
      }
      {
        let { data } = await requestGolang.get("/Gweb/product_all");
        localStorage.setItem("product", JSON.stringify(data));
        this.$store.state.product = data;
      }
      this.$router.push({
        path: "/dashboard",
      });
    },
    async onGolangLogin() {
      await this.$recaptchaLoaded();
      this.model.response = await this.$recaptcha("login");
      //let api_url = "https://dashboard.cvicloud.com:8443/v1/";
      let api_url = "https://dashboard.cvicloud.com/v1/";
      if (process.env.NODE_ENV === "development") {
        api_url = "http://localhost:8080/v1/";
      }
      try {
        let response = await requestGolang.post(
          api_url + "Gweb/token",
          { ...this.model },
          {
            headers: {
              Authorization: process.env.VUE_APP_AUTHORIZATIOH,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          localStorage.setItem("golangToken", JSON.stringify(response.data));
          this.$store.state.golangToken = response.data;
          this.goDashboard();
        } else {
          this.$notify({
            timeout: 2500,
            message: "TAIGA登入失敗",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning",
          });
        }
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "TAIGA登入失敗",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style>
.title {
  font-weight: 400;
}
</style>
