var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"data_form"},[(_vm.tab_index !== '0')?_c('md-button',{staticClass:"md-default",on:{"click":function($event){return _vm.onBack()}}},[_vm._v("返回")]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('md-card',[_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item  md-size-100"},[_c('ValidationProvider',{attrs:{"name":"名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[
                  { 'md-error': failed },
                  { 'md-valid': passed },
                  { 'md-form-group': true }
                ]},[_c('md-icon',[_vm._v("account_box")]),_c('label',[_vm._v("名稱 "+_vm._s(errors[0]))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.model.display_name),callback:function ($$v) {_vm.$set(_vm.model, "display_name", $$v)},expression:"model.display_name"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"email顯示名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('md-field',{class:[
                  { 'md-error': failed },
                  { 'md-valid': passed },
                  { 'md-form-group': true }
                ]},[_c('md-icon',[_vm._v("email")]),_c('label',[_vm._v("email顯示名稱 "+_vm._s(errors[0]))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.model.app_name),callback:function ($$v) {_vm.$set(_vm.model, "app_name", $$v)},expression:"model.app_name"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])})],1),(_vm.model.brand_id)?_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"Brand ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
                var errors = ref.errors;
return [_c('md-field',{class:[
                  { 'md-error': failed },
                  { 'md-valid': passed },
                  { 'md-form-group': true }
                ]},[_c('md-icon',[_vm._v("pin")]),_c('label',[_vm._v("Brand UUID "+_vm._s(errors[0]))]),_c('md-input',{attrs:{"type":"text","readonly":""},model:{value:(_vm.model.brand_id),callback:function ($$v) {_vm.$set(_vm.model, "brand_id", $$v)},expression:"model.brand_id"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,false,634236119)})],1):_vm._e()])])],1),_c('md-card-actions',[(_vm.perm.add_brand)?_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNew),expression:"isNew"}],staticClass:"md-success",on:{"click":function($event){return _vm.onAdd()}}},[_vm._v("新增")]):_vm._e(),(_vm.perm.change_brand)?_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNew),expression:"!isNew"}],staticClass:"md-warning",on:{"click":function($event){return _vm.onEdit()}}},[_vm._v("儲存")]):_vm._e(),(_vm.perm.delete_brand)?_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNew),expression:"!isNew"}],staticClass:"md-danger",on:{"click":function($event){return _vm.onDelete()}}},[_vm._v("刪除")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }