<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header>
          <div class="md-layout">
            <div class="md-layout-item  md-small-size-100 md-size-30">
              <md-field>
                <label for="select">過濾品牌</label>
                <md-select
                  v-model="brand_filter"
                  name=" "
                  style="padding-left:10px"
                  @input="updateProductList()"
                >
                  <md-option
                    v-for="item in brand_list"
                    :key="item.brand_id"
                    :value="item.brand_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30">
              <md-field>
                <label for="select">過濾產品</label>
                <md-select
                  v-model="product_filter"
                  name=" "
                  style="padding-left:10px"
                  @input="updateModelList()"
                >
                  <md-option
                    v-for="item in product_list_fed"
                    :key="item.product_id"
                    :value="item.product_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30">
              <md-field>
                <label for="select">過濾型號</label>
                <md-select v-model="product_model_filter" name=" ">
                  <md-option
                    v-for="item in product_model_fed"
                    :key="item.product_model_id"
                    :value="item.product_model_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <md-table
            v-model="searchedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            @md-selected="onSelectd"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="single"
              ><md-table-cell md-label="FIRMWARE ID" md-sort-by="firmware_id">
                {{ item.firmware_id }}
              </md-table-cell>
              <md-table-cell md-label="Created" md-sort-by="created_at">
                {{ item.created_at | formatDateYYYYMMDDHHMMSS }}
              </md-table-cell>

              <md-table-cell md-label="Version" md-sort-by="int_version">
                {{ item.int_version }}
              </md-table-cell>
              <md-table-cell
                md-label="Display Version"
                md-sort-by="display_veriosn"
              >
                {{ item.display_veriosn }}
              </md-table-cell>
              <md-table-cell md-label="Notes" md-sort-by="notes">
                {{ item.notes }}
              </md-table-cell>

              <md-table-cell md-label="is_published" md-sort-by="is_published">
                <md-icon class="text-success" v-if="item.is_published"
                  >is_done</md-icon
                >
              </md-table-cell>

              <md-table-cell md-label="Model" md-sort-by="product_model_id">
                {{ showProductModelName(item.product_model_id) }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              第 {{ from + 1 }} 到 {{ to }} 項目 於 {{ total }} 表單
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :pageCount="total"
          ></pagination>
        </md-card-actions>
        <md-card-actions> </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination } from "@/components";
import request from "@/utils/requestGolang";
import moment from "moment";
import hasPerm from "@/utils/permission";
import { perm } from "@/utils/permission";

export default {
  components: {
    Pagination
  },
  created: async function() {
    {
      let { data } = await request.get("Gweb/product_all");
      localStorage.setItem("product", JSON.stringify(data));
      this.$store.state.product = data;
      this.product_list = data;
    }
    {
      let { data } = await request.get("Gweb/product_model_list_all");
      this.product_model_list = data;
      localStorage.setItem("product_model", JSON.stringify(data));
      this.$store.state.product_model = data;
      this.product_model_fed = data;
    }
    this.requestData();
    this.selected = null;
    this.perm.view_brand = hasPerm(this.info, perm.ViewBrand);
    if (!this.perm.view_brand) {
      this.brand_filter = this.brand_list[0].brand_id;
      this.updateProductList();
      if (this.product_list_fed[0]) {
        this.product_filter = this.product_list_fed[0].product_id;
        this.updateModelList();
      } else {
        this.product_model_fed = [];
      }
    }
  },
  data: function() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      search_options: [],
      search_model: null,
      searchedData: [],
      selected: null,
      product_model_list: [],
      product_model_fed: [],
      product_model_filter: null,
      brand_list: this.$store.state.brand,
      brand_filter: null,
      product_list: this.$store.state.product,
      product_list_fed: this.$store.state.product,
      product_filter: null,
      url_name: "Gweb/firmware_list",
      currentSort: "product_model_id",
      currentSortOrder: "desc",
      info: this.$store.state.info,
      perm: {
        view_brand: false
      }
    };
  },
  props: {
    filter_query: {
      type: String,
      default: ""
    },
    url_path: {
      type: String,
      default: ""
    },
    tab_index: {
      type: String,
      default: "0"
    }
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    query_url: function() {
      var searchParams = new URLSearchParams(this.filter_query);

      if (this.pagination.currentPage) {
        searchParams.append("page", this.pagination.currentPage);
      }
      if (this.product_model_filter) {
        searchParams.append("model", this.product_model_filter);
      }
      var url = `/${this.url_name}${this.url_path}?${searchParams.toString()}`;

      return url;
    }
  },

  methods: {
    async requestData() {
      let { data } = await request.get(this.query_url);
      this.searchedData = data.data;
      this.customSort(this.searchedData);
      this.pagination.total = data.total;
      this.pagination.perPage = data.per_page;
      this.pagination.currentPage = data.current_page;
    },
    updateProductList() {
      let s = this.brand_filter;
      let newList = this.product_list.filter(function(item, index, array) {
        return item.brand_id === s;
      });
      this.product_list_fed = newList;
    },
    updateModelList() {
      let s = this.product_filter;
      let newList = this.product_model_list.filter(function(
        item,
        index,
        array
      ) {
        return item.product_id === s;
      });
      this.product_model_fed = newList;
    },
    onLink(item) {},
    onTargetSelect(val) {
      this.filter.organization_id = val.organization_id;
      this.filter.organization_name = val.organization_name;
    },
    onTargetChange(value) {
      if (!value) {
        this.filter.organization_id = null;
      }
    },

    onSelectd(item) {
      this.selected = item;
      if (this.tab_index != 4)
        this.$emit("on-selected", this.tab_index, this.selected);
    },
    showProductModelName(item) {
      let product_model_id = this.product_model_list.find(
        i => i.product_model_id == item
      );
      return product_model_id ? product_model_id.display_name : "";
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;

        if (this.currentSortOrder === "desc") {
          return sortBy === "product_model_id"
            ? this.showProductModelName(a[sortBy]).localeCompare(
                this.showProductModelName(b[sortBy])
              )
            : a[sortBy].localeCompare(b[sortBy]);
        }

        return sortBy === "product_model_id"
          ? this.showProductModelName(b[sortBy]).localeCompare(
              this.showProductModelName(a[sortBy])
            )
          : b[sortBy].localeCompare(a[sortBy]);
      });
    }
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    },
    search_model: async function(newData, oldData) {
      if (newData != null) {
        try {
          let { data } = await request.get(
            `GWeb/firmware_list?search=${newData}`
          );
          this.searchedData = data.data;
          this.pagination.total = data.total;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
        } catch (error) {
          this.$notify({
            timeout: 2500,
            message: "網路連線有問題",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning"
          });
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-table-head {
  background-color: lightgray !important;
}
</style>
