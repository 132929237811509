<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <md-list>
            <md-list-item>
              <label>
                {{ userInfo ? userInfo.brand_name : "" }}-
                {{ userInfo ? userInfo.display_name : "" }}
              </label>
            </md-list-item>
            <ul class="md-list-item">
              <div class="md-list-item-content">
                <drop-down direction="down">
                  <md-button
                    slot="title"
                    class="md-button md-just-icon md-simple"
                    data-toggle="dropdown"
                  >
                    <em class="material-icons">person</em>
                  </md-button>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li><a @click="onChangePassword">變更密碼</a></li>
                    <li><a @click="onLogout">登出</a></li>
                  </ul>
                </drop-down>
              </div>
            </ul>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  data() {
    return {
      userInfo: this.$store.state.info
    };
  },

  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    onLogout() {
      // //console.log("登出");
      //localStorage.removeItem("token");
      localStorage.removeItem("golangToken");
      localStorage.removeItem("info");
      localStorage.removeItem("brand");
      this.$store.state.golangToken = null;
      this.$store.state.info = null;
      this.$store.state.brand = null;
      this.$router.push({
        path: "/login"
      });
    },
    onChangePassword() {
      this.$router.push({
        path: "/appcore/change_password"
      });
    }
  }
};
</script>
