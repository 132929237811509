<template>
  <div>
    <md-button class="md-default" @click="onBack()" v-if="tab_index !== '0'"
      >返回</md-button
    >
    <!-- <div>
      <span>{{ model.device_id }}</span>
    </div>
    <div>
      <span>{{ model.product_model_id }}</span>
    </div> -->
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <FHH107
              :device="model"
              v-if="model.product_model_id === FHH107id"
            ></FHH107>
          </div></div></md-card-content
    ></md-card>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import request from "@/utils/requestGolang";
import hasPerm, { perm } from "@/utils/permission";
import FHH107, { FHH107_ID } from "./FHH107.vue";

export default {
  components: {
    FHH107,
  },
  created: async function () {
    this.perm.view_brand = hasPerm(this.info, perm.ViewBrand);
    this.perm.change = hasPerm(this.info, perm.ChangeDevice);
    this.perm.delete = hasPerm(this.info, perm.DeleteDevice);
    if (!this.perm.view_brand) {
      this.brand_filter = this.brand_list[0].brand_id;
      this.updateProductList();
      if (this.product_list_fed[0]) {
        this.product_filter = this.product_list_fed[0].product_id;
        this.updateModelList();
      } else {
        this.product_model_fed = [];
      }
    }
    this.isLoading = false;
    if (this.select_model) {
      this.model = this.select_model;
      this.product_model_filter = this.select_model.product_model_id;
      this.firmware_selected = this.select_model.firmware_id;
      this.updateFirmwareList();
    }
  },

  data: function () {
    return {
      FHH107id: FHH107_ID,
      isLoading: false,
      info: this.$store.state.info,
      perm: {
        change: false,
        delete: false,
        view_brand: false,
      },
      product_model_list: this.$store.state.product_model,
      product_model_fed: this.$store.state.product_model,
      product_model_filter: null,
      brand_list: this.$store.state.brand,
      brand_filter: null,
      product_list: this.$store.state.product,
      product_list_fed: this.$store.state.product,
      product_filter: null,
      firmware_list: [],
      firmware_selected: null,
      url_name: "Gweb/device",
      permission_name: "deviceinfo",
      model: {
        // at_gateway_index: "",
        // aws_arn: "",
        // aws_id: "",
        device_id: "",
        //devices_region_id: "",
        display_name: "",
        //endpoint_address: "",
        firmware_id: null,
        firmware_version: 1,
        // full_gateway_index: "",
        // gateway_id: null,
        // is_need_update: false,
        mac: "",
        owner_id: null,
        owner: {
          email: "",
        },
        product_model_id: null,
        // region_name: "",
        // room_id: null,
        serial: "",
        /* service_host_type: 0,
        top_gateway_id: null */
        be_logged: false,
      },
      add_response_flag: false,
      add_response: {
        certificatePem: "",
        certificateArn: "",
        certificateId: "",
        PrivateKey: "",
        device_id: "",
        endpoint_address: "",
      },
      unbind: false,
    };
  },
  props: {
    select_model: Object,
    tab_index: {
      type: String,
      default: "0",
    },
  },

  computed: {},
  methods: {
    updateProductList() {
      let s = this.brand_filter;
      let newList = this.product_list.filter(function (item, index, array) {
        return item.brand_id === s;
      });
      this.product_list_fed = newList;
    },
    updateModelList() {
      let s = this.product_filter;
      let newList = this.product_model_list.filter(function (
        item,
        index,
        array
      ) {
        return item.product_id === s;
      });
      this.product_model_fed = newList;
    },
    async updateFirmwareList() {
      let { data } = await request.get(
        "Gweb/firmware_all?model=" + this.product_model_filter
      );
      this.firmware_list = data;
    },
    selectFirmware() {
      this.model.product_model_id = this.product_model_filter;
      this.model.firmware_id = this.firmware_selected;
      let firmware = this.firmware_list.find(
        (i) => i.firmware_id == this.firmware_selected
      );
      this.model.firmware_version = firmware.int_version;
    },
    onBack() {
      this.$emit("on-back", this.tab_index);
    },
    // todo permission
    async onAdd() {
      let isvalided = await this.validate();
      if (isvalided) {
        try {
          let { data } = await request.post(`/factory/device/create`, {
            mac: this.model.mac,
            fireware_id: this.firmware_selected,
            name: this.model.display_name,
            serial: this.model.serial,
          });
          this.add_response = data;
          this.add_response_flag = true;
          this.model.user_id = "have";
          this.$notify({
            timeout: 2500,
            message: "創建成功",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success",
          });
        } catch (error) {
          this.$notify({
            timeout: 2500,
            message: "error",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success",
          });
        }
      }
    },
    async onEdit() {
      try {
        let pushData = { ...this.model };
        delete pushData.owner;
        delete pushData.firmware;
        pushData["unbind"] = this.unbind;
        /*let { data } =*/ await request.put(
          `/${this.url_name}/${this.model.device_id}`,
          pushData
        );
        this.$notify({
          timeout: 2500,
          message: "修改成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
      }
    },
    async onDelete() {
      Swal.fire({
        title: "確定要刪除嗎?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.dismiss) {
          return;
        }
        await this.onDeleteApi();
        this.$emit("on-back", this.tab_index);
      });
    },
    async onDeleteApi() {
      try {
        /*let { data } =*/ await request.delete(
          `/${this.url_name}/${this.model.device_id}`,
          {}
        );
        this.$notify({
          timeout: 2500,
          message: "刪除成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
        this.$emit("on-back", this.tab_index);
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
      }
    },
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    },
  },
};
</script>

<style lang="css" scoped>
.error.md-icon {
  right: 20px;
}
.success.md-icon {
  right: 20px;
}
</style>
