<template>
  <ValidationObserver ref="data_form">
    <md-button class="md-default" @click="onBack()" v-if="tab_index !== '0'"
      >返回</md-button
    >
    <form @submit.prevent="validate">
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item  md-size-100">
              <ValidationProvider
                name="帳號"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[
                    { 'md-error': failed },
                    { 'md-valid': passed },
                    { 'md-form-group': true }
                  ]"
                >
                  <md-icon>account_box</md-icon>
                  <label>帳號 {{ errors[0] }}</label>
                  <md-input
                    v-model="model.email"
                    type="text"
                    :readonly="!isNew"
                  ></md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item  md-size-100" v-show="isNew">
              <ValidationProvider
                name="密碼"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[
                    { 'md-error': failed },
                    { 'md-valid': passed },
                    { 'md-form-group': true }
                  ]"
                >
                  <md-icon>lock_outline</md-icon>
                  <label>密碼 {{ errors[0] }}</label>
                  <md-input v-model="model.password" type="password"></md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-100">
              <ValidationProvider
                name="姓名"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[
                    { 'md-error': failed },
                    { 'md-valid': passed },
                    { 'md-form-group': true }
                  ]"
                >
                  <md-icon>face</md-icon>
                  <label>姓名 {{ errors[0] }}</label>
                  <md-input v-model="model.display_name" type="text"></md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-layout md-size-100">
              <md-field>
                <md-icon>brand</md-icon>
                <label for="select">廠牌</label>
                <md-select v-model="model.brand_id" name=" " :disabled="!isNew">
                  <md-option
                    v-for="item in brand_list"
                    :key="item.brand_id"
                    :value="item.brand_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout-item  md-size-100" v-if="tab_index !== '0'">
            <md-field>
              <md-icon>pin</md-icon>
              <label>User ID</label>
              <md-input v-model="model.user_id" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-checkbox v-model="model.is_active">啟用</md-checkbox>
          </div>
        </md-card-content>
        <md-card-content v-if="perm.view_perm && tab_index !== '0'">
          <label>後台管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="model.is_staff"
                :disabled="!perm.change_perm"
                >後台登入權限</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="model.is_super_user"
                :disabled="!perm.change_perm"
                >超級使用者</md-checkbox
              >
            </div>
          </div>
          <label>權限管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_perm"
                :disabled="!perm.change_perm"
                >可觀看權限</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_perm"
                :disabled="!perm.change_perm"
                >可修改權限</md-checkbox
              >
            </div>
          </div>
          <label>使用者管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_users"
                :disabled="!perm.change_perm"
                >可列出使用者</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_users"
                :disabled="!perm.change_perm"
                >可新增修改使用者</md-checkbox
              >
            </div>
          </div>
          <label>品牌管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_brand"
                :disabled="!perm.change_perm"
                >可列出品牌</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.add_brand"
                :disabled="!perm.change_perm"
                >可新增品牌</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_brand"
                :disabled="!perm.change_perm"
                >可修改品牌</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.delete_brand"
                :disabled="!perm.change_perm"
                >可刪除品牌</md-checkbox
              >
            </div>
          </div>
          <label>產品管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_product"
                :disabled="!perm.change_perm"
                >可列出產品</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.add_product"
                :disabled="!perm.change_perm"
                >可新增產品</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_product"
                :disabled="!perm.change_perm"
                >可修改產品</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.delete_product"
                :disabled="!perm.change_perm"
                >可刪除產品</md-checkbox
              >
            </div>
          </div>
          <label>型號管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_model"
                :disabled="!perm.change_perm"
                >可列出型號</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.add_model"
                :disabled="!perm.change_perm"
                >可新增型號</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_model"
                :disabled="!perm.change_perm"
                >可修改型號</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.delete_model"
                :disabled="!perm.change_perm"
                >可刪除型號</md-checkbox
              >
            </div>
          </div>
          <label>韌體管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_firmware"
                :disabled="!perm.change_perm"
                >可列出韌體</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.add_firmware"
                :disabled="!perm.change_perm"
                >可新增韌體</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_firmware"
                :disabled="!perm.change_perm"
                >可修改韌體</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.delete_firmware"
                :disabled="!perm.change_perm"
                >可刪除韌體</md-checkbox
              >
            </div>
          </div>
          <label>裝置管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_device"
                :disabled="!perm.change_perm"
                >可列出裝置</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.add_device"
                :disabled="!perm.change_perm"
                >可新增裝置</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_device"
                :disabled="!perm.change_perm"
                >可修改裝置</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.delete_device"
                :disabled="!perm.change_perm"
                >可刪除裝置</md-checkbox
              >
            </div>
          </div>
          <label>Home管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_home"
                :disabled="!perm.change_perm"
                >可列出Home</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.add_home"
                :disabled="!perm.change_perm"
                >可新增Home</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_home"
                :disabled="!perm.change_perm"
                >可修改Home</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.delete_home"
                :disabled="!perm.change_perm"
                >可刪除Home</md-checkbox
              >
            </div>
          </div>
          <label>Room管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_room"
                :disabled="!perm.change_perm"
                >可列出Room</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.add_room"
                :disabled="!perm.change_perm"
                >可新增Room</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_room"
                :disabled="!perm.change_perm"
                >可修改Room</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.delete_room"
                :disabled="!perm.change_perm"
                >可刪除Room</md-checkbox
              >
            </div>
          </div>
          <label>Zone管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_zone"
                :disabled="!perm.change_perm"
                >可列出Zone</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.add_zone"
                :disabled="!perm.change_perm"
                >可新增Zone</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.change_zone"
                :disabled="!perm.change_perm"
                >可修改Zone</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.delete_zone"
                :disabled="!perm.change_perm"
                >可刪除Zone</md-checkbox
              >
            </div>
          </div>
          <label>尿布感測器管理員</label>
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_baby"
                :disabled="!perm.change_perm"
                >可列出幼兒版事件</md-checkbox
              >
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <md-checkbox
                v-model="target_perms.view_old"
                :disabled="!perm.change_perm"
                >可列出成人版事件</md-checkbox
              >
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card-actions>
        <md-button
          class="md-success"
          @click="onAdd()"
          v-show="isNew"
          v-if="perm.change_users"
          >新增</md-button
        >
        <md-button
          class="md-warning"
          @click="onEdit()"
          v-show="!isNew"
          v-if="perm.change_users"
          >儲存</md-button
        >
        <md-button
          class="md-danger"
          @click="onDelete()"
          v-show="!isNew"
          v-if="perm.change_users"
          >刪除</md-button
        >
      </md-card-actions>
    </form>
  </ValidationObserver>
</template>
<script>
import Swal from "sweetalert2";
import request from "@/utils/requestGolang";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { localize } from "vee-validate";
import zh_TW from "vee-validate/dist/locale/zh_TW.json";
import hasPerm from "@/utils/permission";
import { perm } from "@/utils/permission";

localize("zh_TW", zh_TW);
extend("required", required);
extend("email", email);

export default {
  components: {},
  created: async function() {
    this.isLoading = false;
    if (this.select_model) this.model = this.select_model;
    this.perm.view_perm = hasPerm(this.info, perm.ViewPermission);
    this.perm.change_perm = hasPerm(this.info, perm.ChangePermission);
    this.perm.change_users = hasPerm(this.info, perm.ChangeUsers);
    if (this.perm.view_perm) {
      this.permArray2Obj();
    }
  },

  data: function() {
    return {
      isLoading: false,
      info: this.$store.state.info,
      brand_list: this.$store.state.brand,
      permission_name: "customer",
      url_name: "Gweb/user",
      model: {
        brand_id: "",
        display_name: "",
        email: "",
        password: "",
        user_id: "",
        is_active: false,
        is_staff: false,
        is_super_user: false,
        id: null,
        Perm: null
      },
      perm: {
        view_perm: false,
        change_perm: false,
        view_users: false,
        change_users: false
      },
      target_perms: {
        view_perm: false,
        change_perm: false,
        view_users: false,
        change_users: false,
        view_baby: false,
        view_old: false,
        view_brand: false,
        add_brand: false,
        change_brand: false,
        delete_brand: false,
        view_product: false,
        add_product: false,
        change_product: false,
        delete_product: false,
        view_firmware: false,
        add_firmware: false,
        change_firmware: false,
        delete_firmware: false,
        view_device: false,
        add_device: false,
        change_device: false,
        delete_device: false,
        view_home: false,
        add_home: false,
        change_home: false,
        delete_home: false,
        view_room: false,
        add_room: false,
        change_room: false,
        delete_room: false,
        view_zone: false,
        add_zone: false,
        change_zone: false,
        delete_zone: false,
        view_model: false,
        add_model: false,
        change_model: false,
        delete_model: false
      }
    };
  },
  props: {
    select_model: Object,
    tab_index: {
      type: String,
      default: "0"
    }
  },

  computed: {
    isNew() {
      return this.model.user_id.length === 0;
    }
  },
  methods: {
    validate() {
      return this.$refs.data_form.validate().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
    onBack() {
      this.$emit("on-back", this.tab_index);
    },
    // todo permission

    async onAdd() {
      let isvalided = await this.validate();
      if (isvalided) {
        if (!this.model.brand_id) {
          this.$notify({
            timeout: 2500,
            message: "廠牌未選",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success"
          });
          return;
        }
        try {
          let pushData = { ...this.model };
          delete pushData.user_id;
          let { data } = await request.post(`/${this.url_name}`, {
            ...this.model
          });
          this.model.user_id = "have";
          this.$emit("on-back", this.tab_index);
          this.$notify({
            timeout: 2500,
            message: "創建成功",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success"
          });
        } catch (error) {
          this.$notify({
            timeout: 2500,
            message: "error",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success"
          });
        }
      }
    },
    async onEdit() {
      if (this.perm.change_perm) {
        this.permObj2Ids();
      }
      try {
        let { data } = await request.put(
          `/${this.url_name}?id=${this.model.id}`,
          {
            ...this.model
          }
        );
        this.$notify({
          timeout: 2500,
          message: "修改成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
        if (this.model.user_id == this.info.user_id) {
          this.updateInfo();
        }
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
      }
    },
    async updateInfo() {
      let { data } = await request.get("/Gweb/user/info");
      if (!data.is_staff && !data.is_super_user) {
        this.$notify({
          timeout: 2500,
          message: "登入失敗",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "warning"
        });
        this.$router.push({
          path: "/login"
        });
      }
      {
        let { data } = await request.get("/Gweb/brand_all", {
          headers: {
            Authorization: process.env.VUE_APP_AUTHORIZATIOH
          }
        });
        localStorage.setItem("brand", JSON.stringify(data));
        this.$store.state.brand = data;
      }
      let brand = this.brand_list.find(i => i.brand_id == data.brand_id);
      data["brand_name"] = brand ? brand.display_name : "";
      this.$store.state.info = data;
      localStorage.setItem("info", JSON.stringify(data));
      if (!hasPerm(data, perm.ViewBrand)) {
        localStorage.setItem("brand", JSON.stringify([brand]));
        this.$store.state.brand = [brand];
      }
      location.reload();
    },
    async onDelete() {
      Swal.fire({
        title: "確定要刪除嗎?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        buttonsStyling: false
      }).then(async result => {
        if (result.dismiss) {
          return;
        }
        await this.onDeleteApi();
        this.$emit("on-back", this.tab_index);
      });
    },
    async onDeleteApi() {
      try {
        let { data } = await request.delete(
          `/${this.url_name}?id=${this.model.id}`
        );
        this.$notify({
          timeout: 2500,
          message: "刪除成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
        this.$emit("on-back", this.tab_index);
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
      }
    },
    permArray2Obj() {
      for (const p of this.model.Perm) {
        switch (p.permission_id) {
          case perm.ChangePermission:
            this.target_perms.change_perm = true;
            break;
          case perm.ViewPermission:
            this.target_perms.view_perm = true;
            break;
          case perm.ChangeUsers:
            this.target_perms.change_users = true;
            break;
          case perm.ViewUsers:
            this.target_perms.view_users = true;
            break;
          case perm.ViewBaby:
            this.target_perms.view_baby = true;
            break;
          case perm.ViewOld:
            this.target_perms.view_old = true;
            break;
          case perm.ViewBrand:
            this.target_perms.view_brand = true;
            break;
          case perm.AddBrand:
            this.target_perms.add_brand = true;
            break;
          case perm.ChangeBrand:
            this.target_perms.change_brand = true;
            break;
          case perm.DeleteBrand:
            this.target_perms.delete_brand = true;
            break;
          case perm.ViewProduct:
            this.target_perms.view_product = true;
            break;
          case perm.AddProduct:
            this.target_perms.add_product = true;
            break;
          case perm.ChangeProduct:
            this.target_perms.change_product = true;
            break;
          case perm.DeleteProduct:
            this.target_perms.delete_product = true;
            break;
          case perm.ViewModel:
            this.target_perms.view_model = true;
            break;
          case perm.AddModel:
            this.target_perms.add_model = true;
            break;
          case perm.ChangeModel:
            this.target_perms.change_model = true;
            break;
          case perm.DeleteModel:
            this.target_perms.delete_model = true;
            break;
          case perm.ViewFirmware:
            this.target_perms.view_firmware = true;
            break;
          case perm.AddFirmware:
            this.target_perms.add_firmware = true;
            break;
          case perm.ChangeFirmware:
            this.target_perms.change_firmware = true;
            break;
          case perm.DeleteFirmware:
            this.target_perms.delete_firmware = true;
            break;
          case perm.ViewDevice:
            this.target_perms.view_device = true;
            break;
          case perm.AddDevice:
            this.target_perms.add_device = true;
            break;
          case perm.ChangeDevice:
            this.target_perms.change_device = true;
            break;
          case perm.DeleteDevice:
            this.target_perms.delete_device = true;
            break;
          case perm.ViewHome:
            this.target_perms.view_home = true;
            break;
          case perm.AddHome:
            this.target_perms.add_home = true;
            break;
          case perm.ChangeHome:
            this.target_perms.change_home = true;
            break;
          case perm.DeleteHome:
            this.target_perms.delete_home = true;
            break;
          case perm.ViewRoom:
            this.target_perms.view_room = true;
            break;
          case perm.AddRoom:
            this.target_perms.add_room = true;
            break;
          case perm.ChangeRoom:
            this.target_perms.change_room = true;
            break;
          case perm.DeleteRoom:
            this.target_perms.delete_room = true;
            break;
          case perm.ViewZone:
            this.target_perms.view_zone = true;
            break;
          case perm.AddZone:
            this.target_perms.add_zone = true;
            break;
          case perm.ChangeZone:
            this.target_perms.change_zone = true;
            break;
          case perm.DeleteZone:
            this.target_perms.delete_zone = true;
            break;
          default:
            break;
        }
      }
    },
    permObj2Ids() {
      this.model.permission_ids = [];
      if (this.target_perms.change_perm)
        this.model.permission_ids.push(perm.ChangePermission);
      if (this.target_perms.view_perm)
        this.model.permission_ids.push(perm.ViewPermission);
      if (this.target_perms.change_users)
        this.model.permission_ids.push(perm.ChangeUsers);
      if (this.target_perms.view_users)
        this.model.permission_ids.push(perm.ViewUsers);
      if (this.target_perms.view_baby)
        this.model.permission_ids.push(perm.ViewBaby);
      if (this.target_perms.view_old)
        this.model.permission_ids.push(perm.ViewOld);
      if (this.target_perms.view_brand)
        this.model.permission_ids.push(perm.ViewBrand);
      if (this.target_perms.add_brand)
        this.model.permission_ids.push(perm.AddBrand);
      if (this.target_perms.change_brand)
        this.model.permission_ids.push(perm.ChangeBrand);
      if (this.target_perms.delete_brand)
        this.model.permission_ids.push(perm.DeleteBrand);
      if (this.target_perms.view_product)
        this.model.permission_ids.push(perm.ViewProduct);
      if (this.target_perms.add_product)
        this.model.permission_ids.push(perm.AddProduct);
      if (this.target_perms.change_product)
        this.model.permission_ids.push(perm.ChangeProduct);
      if (this.target_perms.delete_product)
        this.model.permission_ids.push(perm.DeleteProduct);
      if (this.target_perms.view_model)
        this.model.permission_ids.push(perm.ViewModel);
      if (this.target_perms.add_model)
        this.model.permission_ids.push(perm.AddModel);
      if (this.target_perms.change_model)
        this.model.permission_ids.push(perm.ChangeModel);
      if (this.target_perms.delete_model)
        this.model.permission_ids.push(perm.DeleteModel);
      if (this.target_perms.view_firmware)
        this.model.permission_ids.push(perm.ViewFirmware);
      if (this.target_perms.add_firmware)
        this.model.permission_ids.push(perm.AddFirmware);
      if (this.target_perms.change_firmware)
        this.model.permission_ids.push(perm.ChangeFirmware);
      if (this.target_perms.delete_firmware)
        this.model.permission_ids.push(perm.DeleteFirmware);
      if (this.target_perms.view_device)
        this.model.permission_ids.push(perm.ViewDevice);
      if (this.target_perms.add_device)
        this.model.permission_ids.push(perm.AddDevice);
      if (this.target_perms.change_device)
        this.model.permission_ids.push(perm.ChangeDevice);
      if (this.target_perms.delete_device)
        this.model.permission_ids.push(perm.DeleteDevice);
      if (this.target_perms.view_home)
        this.model.permission_ids.push(perm.ViewHome);
      if (this.target_perms.add_home)
        this.model.permission_ids.push(perm.AddHome);
      if (this.target_perms.change_home)
        this.model.permission_ids.push(perm.ChangeHome);
      if (this.target_perms.delete_home)
        this.model.permission_ids.push(perm.DeleteHome);
      if (this.target_perms.view_room)
        this.model.permission_ids.push(perm.ViewRoom);
      if (this.target_perms.add_room)
        this.model.permission_ids.push(perm.AddRoom);
      if (this.target_perms.change_room)
        this.model.permission_ids.push(perm.ChangeRoom);
      if (this.target_perms.delete_room)
        this.model.permission_ids.push(perm.DeleteRoom);
      if (this.target_perms.view_zone)
        this.model.permission_ids.push(perm.ViewZone);
      if (this.target_perms.add_zone)
        this.model.permission_ids.push(perm.AddZone);
      if (this.target_perms.change_zone)
        this.model.permission_ids.push(perm.ChangeZone);
      if (this.target_perms.delete_zone)
        this.model.permission_ids.push(perm.DeleteZone);
    }
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    }
  }
};
</script>

<style lang="css" scoped>
.error.md-icon {
  right: 20px;
}
.success.md-icon {
  right: 20px;
}
</style>
