<template>
  <md-card
    class="md-card-tabs"
    :class="[
      { 'flex-column': flexColumn },
      { 'nav-pills-icons': navPillsIcons },
      { 'md-card-plain': plain }
    ]"
  >
    <md-card-header>
      <slot name="header-title"></slot>
    </md-card-header>
    <md-card-content>
      <md-list class="nav-tabs">
        <md-list-item
          v-for="(item, index) in tabName"
          @click="switchPanel(tabName[index], index)"
          :key="item"
          :class="[
            { active: isActivePanel(tabName[index]) },
            { [getColorButton(colorButton)]: isActivePanel(tabName[index]) }
          ]"
          v-show="tabShow[index]"
        >
          {{ tabName[index] }}
          <md-icon v-if="navPillsIcons">{{ tabIcon[index] }}</md-icon>
          <div class="notification" v-if="tabCount[index] > 0">
            {{ tabCount[index] }}
          </div>
        </md-list-item>
      </md-list>

      <transition name="fade" mode="out-in">
        <div class="tab-content">
          <template v-for="(item, index) in tabName">
            <template v-if="isActivePanel(tabName[index])">
              <div
                :class="getTabContent(index + 1)"
                class="tab-pane-full"
                :key="item"
              >
                <slot :name="getTabContent(index + 1)"></slot>
              </div>
            </template>
          </template>
        </div>
      </transition>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  props: {
    flexColumn: Boolean,
    navPillsIcons: Boolean,
    plain: Boolean,
    tabName: Array,
    tabIcon: Array,
    tabShow: {
      type: Array,
      default: () => [true, true, true, true, true, true, true, true]
    },
    tabCount: {
      type: Array,
      default: () => [0, 0, 0, 0, 0, 0, 0, 0]
    },
    tabInitIndex: {
      type: Number,
      default: 0
    },
    colorButton: {
      type: String,
      default: ""
    },
    mPanel: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      activePanel: this.tabName[this.tabInitIndex]
    };
  },
  methods: {
    switchPanel(panel, index) {
      this.activePanel = panel;
      this.$emit("on-switchPanel", index);
    },
    isActivePanel(panel) {
      return this.activePanel == panel;
    },
    getColorButton: function(colorButton) {
      return "md-" + colorButton + "";
    },
    getTabContent: function(index) {
      return "tab-pane-" + index + "";
    }
  },
  watch: {
    async mPanel(value) {
      this.activePanel = value;
    }
  }
};
</script>

<style lang="css">
.tab-pane-full {
  width: 100%;
}
.nav-tabs .notification {
  position: absolute;
  top: 0px;
  border: 1px solid #fff;
  right: 0px;
  font-size: 9px;
  background: #f44336;
  color: #ffffff;
  z-index: 1;
  min-width: 20px;
  padding: 0px 5px;
  height: 20px;
  border-radius: 10px !important;
  text-align: center;
  line-height: 19px;
  vertical-align: middle;
  display: block;
}
</style>
