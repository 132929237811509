import axios from "axios";
import store from "@/store/index";

//let api_url = "https://dashboard.cvicloud.com:8443/v1/";
let api_url = "https://dashboard.cvicloud.com/v1/";
if (process.env.NODE_ENV === "development") {
  api_url = "http://localhost:8081/v1/";
}
const requestGolang = axios.create({
  baseURL: api_url,
  headers: {
    "Content-Type": "application/json"
  },
  responseEncoding: "utf8",
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken"
});
//
let whiteList = ["/user/create", "/Gweb/token", "/Gweb/brand_all"];
requestGolang.interceptors.request.use(
  config => {
    let white = whiteList.some(item => config.url.includes(item));
    if (white) {
      return config;
    }
    if (store.state.golangToken && store.state.golangToken.access_token) {
      config.headers.Authorization = `Bearer ${store.state.golangToken.access_token}`;
    } else {
      config.headers.Authorization = process.env.VUE_APP_AUTHORIZATIOH;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

requestGolang.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      const refresh_token =
        (store.state.golangToken && store.state.golangToken.refresh_token) ||
        "";
      if (refresh_token.length === 0) {
        return Promise.reject("");
      }
      return axios
        .post(
          api_url + "Gweb/token",
          {
            refresh_token: refresh_token,
            grant_type: "refresh_token"
          },
          {
            headers: {
              Authorization: process.env.VUE_APP_API_TOKEN
            }
          }
        )
        .then(response => {
          let token = {
            ...response.data
          };
          localStorage.setItem("golangToken", JSON.stringify(token));
          store.state.golangToken = token;
          const auth = `Bearer ${token.access_token}`;
          requestGolang.defaults.headers["Authorization"] = auth;
          originalRequest.headers["Authorization"] = auth;
          return requestGolang(originalRequest);
        })
        .catch(err => {
          console.log(err);
          localStorage.removeItem("golangToken");
          localStorage.removeItem("token");
          localStorage.removeItem("info");
          localStorage.removeItem("brand");
          this.$store.state.golangToken = null;
          this.$store.state.info = null;
          this.$store.state.brand = null;
          window.location.href = "/";
        });
    }
    return Promise.reject(error);
  }
);

export default requestGolang;
