<template>
  <div class="md-layout" v-if="isLoading">Loading...</div>
  <div class="md-layout" v-else>
    <chart-card
      :chart-data="power_chart"
      :chart-options="options"
      chart-type="Line"
      chart-inside-header
      background-color="blue"
    >
      <template slot="content">
        <h4 class="title">{{ power_title + power_max + "W" }}</h4>
      </template>
    </chart-card>
  </div>
</template>
<script>
import request from "@/utils/requestGolang";
import {
  //StatsCard,
  ChartCard,
  //AnimatedNumber
  /*,
  GlobalSalesCard,
  GlobalSalesTable, */
} from "@/components";
import { format, parseISO } from "date-fns";

export default {
  components: {
    ChartCard,
  },
  name: "FHH107",
  data() {
    return {
      isLoading: true,
      power_arr: [],
      record_at_X_axis: [],
      record_at_time: [],
      record_length: 0,
      record_interval: 1,
      power_max: 0,
      LogData: [],
      power_chart: {
        labels: [],
        series: [],
      },
      power_title: "Power (Watt), Max Power is ",
      options: {
        lineSmooth: this.$Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        height: "230px",
        axisX: {
          labelInterpolationFnc: function (value, index) {
            //const date = parseISO(value);
            return value ? format(value, "MMM/dd") : null;
          },
        },
      },
      model: {
        // at_gateway_index: "",
        // aws_arn: "",
        // aws_id: "",
        device_id: "",
        //devices_region_id: "",
        display_name: "",
        //endpoint_address: "",
        firmware_id: null,
        firmware_version: 1,
        // full_gateway_index: "",
        // gateway_id: null,
        // is_need_update: false,
        mac: "",
        owner_id: null,
        owner: {
          email: "",
        },
        product_model_id: null,
        // region_name: "",
        // room_id: null,
        serial: "",
        /* service_host_type: 0,
        top_gateway_id: null */
        be_logged: false,
      },
    };
  },
  props: {
    device: {
      type: Object,
      default: null,
    },
  },
  methods: {
    async requestData() {
      let { data } = await request.post("/log/month", {
        DID: this.model.device_id.replaceAll("-", ""),
      });
      this.LogData = data;
      this.record_length = data.length;
      this.record_interval = Math.ceil(data.length / 15);
      this.power_arr = data.map((v) => v.data["04"]);
      this.record_at_time = data.map((v, i) => parseISO(v.record_at));
      this.record_at_X_axis = data.map((v, i) =>
        i % this.record_interval === 0 ? parseISO(v.record_at) : null
      );
      //this.power_max = Math.max(...this.power_arr);
      data.map((v) => {
        if (v.data["04"] > this.power_max) {
          this.power_max = v.data["04"];
        }
      });
      //this.power_title = this.power_title + this.power_max + " W";
      this.power_chart = {
        labels: this.record_at_X_axis,
        series: [this.power_arr],
      };
      this.isLoading = false;
    },
  },
  created: async function () {
    if (this.device) {
      this.model = this.device;
    }
  },
  mounted() {
    this.requestData();
  },
  unmounted() {
    this.beBreak = true;
  },
};
export const FHH107_ID = "109915fb-29a4-4713-8ae0-b544a17a8b1b";
</script>
