<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-autocomplete
                class="search"
                v-model="search_model"
                :md-options="search_options"
                @md-selected="onTargetSelect"
                @md-changed="onTargetChange"
              >
                <label>uuid / mac / serial</label>
                <template slot="md-autocomplete-item" slot-scope="{ item }">{{
                  item
                }}</template>
              </md-autocomplete>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-30">
              <md-field>
                <label for="select">過濾品牌以選擇型號</label>
                <md-select
                  v-model="brand_filter"
                  name=" "
                  style="padding-left: 10px"
                  @input="updateProductList()"
                >
                  <md-option
                    v-for="item in brand_list"
                    :key="item.brand_id"
                    :value="item.brand_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30">
              <md-field>
                <label for="select">過濾產品以選擇型號</label>
                <md-select
                  v-model="product_filter"
                  name=" "
                  style="padding-left: 10px"
                  @input="updateModelList()"
                >
                  <md-option
                    v-for="item in product_list_fed"
                    :key="item.product_id"
                    :value="item.product_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30">
              <md-field>
                <label for="select">過濾型號</label>
                <md-select v-model="product_model_filter" name=" ">
                  <md-option
                    v-for="item in product_model_fed"
                    :key="item.product_model_id"
                    :value="item.product_model_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="searchedData"
            @md-selected="onSelectd"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="single"
            >
              <md-table-cell md-label="uuid">
                {{ item.device_id }}
              </md-table-cell>

              <md-table-cell md-label="Name">
                {{ item.display_name }}
              </md-table-cell>
              <md-table-cell md-label="MAC">
                {{ item.mac }}
              </md-table-cell>
              <md-table-cell md-label="serial">
                {{ item.serial }}
              </md-table-cell>
              <md-table-cell md-label="產品型號">
                {{ showProductModelName(item.product_model_id) }}
              </md-table-cell>
              <md-table-cell md-label="firmware">
                {{ item.firmware ? item.firmware.display_veriosn : "" }}
              </md-table-cell>
              <md-table-cell md-label="Owner">
                {{ item.owner ? item.owner.email : "" }}
              </md-table-cell>
              <md-table-cell md-label="紀錄">
                <md-icon>{{ item.be_logged ? "check_circle" : "" }}</md-icon>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              第 {{ from + 1 }} 到 {{ to }} 項目 於 {{ total }} 表單
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :pageCount="total"
          ></pagination>
        </md-card-actions>
        <md-card-actions> </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination } from "@/components";
import request from "@/utils/requestGolang";
import HasPermission, { perm } from "@/utils/permission";

export default {
  components: {
    Pagination,
  },
  created: async function () {
    this.perm.view_brand = HasPermission(this.info, perm.ViewBrand);
    if (!this.perm.view_brand) {
      this.brand_filter = this.brand_list[0].brand_id;
      this.updateProductList();
      if (this.product_list_fed[0]) {
        this.product_filter = this.product_list_fed[0].product_id;
        this.updateModelList();
      } else {
        this.product_model_fed = [];
      }
    }
    {
      let { data } = await request.get("Gweb/product_all");
      localStorage.setItem("product", JSON.stringify(data));
      this.$store.state.product = data;
      this.product_list = data;
    }
    {
      let { data } = await request.get("Gweb/product_model_list_all");
      this.product_model_list = data;
      localStorage.setItem("product_model", JSON.stringify(data));
      this.$store.state.product_model = data;
      this.product_model_fed = data;
    }
    this.requestData();
    this.selected = null;
  },
  data: function () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      search_options: [],
      search_model: null,
      searchedData: [],
      selected: null,
      product_model_list: [],
      product_model_fed: [],
      product_model_filter: null,
      brand_list: this.$store.state.brand,
      brand_filter: null,
      product_list: this.$store.state.product,
      product_list_fed: this.$store.state.product,
      product_filter: null,
      url_name: "Gweb/device_list",
      info: this.$store.state.info,
      perm: {
        view_brand: false,
      },
    };
  },
  props: {
    filter_query: {
      type: String,
      default: "",
    },
    url_path: {
      type: String,
      default: "",
    },
    tab_index: {
      type: String,
      default: "0",
    },
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    query_url: function () {
      let searchParams = new URLSearchParams(this.filter_query);

      if (this.pagination.currentPage) {
        searchParams.append("page", this.pagination.currentPage);
      }
      if (this.search_model) {
        searchParams.append("search", this.search_model);
      }
      if (this.product_model_filter) {
        searchParams.append("model", this.product_model_filter);
      }
      let url = `/${this.url_name}?${searchParams.toString()}`;

      return url;
    },
  },

  methods: {
    async requestData() {
      let { data } = await request.get(this.query_url);
      this.searchedData = data.data;
      this.pagination.total = data.total;
      this.pagination.perPage = data.per_page;
      this.pagination.currentPage = data.current_page;
    },
    showProductModelName(item) {
      let product_model_id = this.product_model_list.find(
        (i) => i.product_model_id == item
      );
      return product_model_id ? product_model_id.display_name : "";
    },
    updateProductList() {
      let s = this.brand_filter;
      let newList = this.product_list.filter(function (item, index, array) {
        return item.brand_id === s;
      });
      this.product_list_fed = newList;
    },
    updateModelList() {
      let s = this.product_filter;
      let newList = this.product_model_list.filter(function (
        item,
        index,
        array
      ) {
        return item.product_id === s;
      });
      this.product_model_fed = newList;
    },
    onTargetSelect(val) {
      this.filter.organization_id = val.organization_id;
      this.filter.organization_name = val.organization_name;
    },
    onTargetChange(value) {
      if (!value) {
        this.filter.organization_id = null;
      }
    },

    onSelectd(item) {
      this.selected = item;
      if (this.tab_index != 4)
        this.$emit("on-selected", this.tab_index, this.selected);
    },
    showBrandName(item) {
      let brand = this.brand_list.find((i) => i.brand_id == item);
      return brand ? brand.display_name : "";
    },
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    },
    search_model: async function (newData, oldData) {
      if (newData != null) {
        try {
          let { data } = await request.get(
            `/${this.url_name}?search=${newData}`
          );
          this.searchedData = data.data;
          this.pagination.total = data.total;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
        } catch (error) {
          this.$notify({
            timeout: 2500,
            message: "網路連線有問題",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning",
          });
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-table-head {
  background-color: lightgray !important;
}
</style>
