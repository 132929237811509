<template>
  <div>
    <md-table v-model="users" class="md-table-global-sales">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell>{{ item.country }}</md-table-cell>
        <md-table-cell>{{ item.sales }}</md-table-cell>
        <md-table-cell>{{ item.percent }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "global-sales-table",
  data() {
    return {
      selected: [],
      users: [
        {
          country: "ap-norheast-1",
          sales: "2.930",
          percent: "53.23%"
        },
        {
          country: "us-east-1",
          sales: "1.300",
          percent: "20.43%"
        },
        {
          country: "eu-central-1",
          sales: "760",
          percent: "10.35%"
        },
        {
          country: "me-south-1",
          sales: "690",
          percent: "7.87%"
        },
        {
          country: "ap-southeast-2",
          sales: "600",
          percent: "5.94%"
        }
      ]
    };
  }
};
</script>
