<template>
  <div class="md-layout text-center">
    <notifications></notifications>
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <login-card header-color="green">
        <h3 slot="title" class="title">CviCloud</h3>
        <h4 slot="buttons" class="title">{{ model.username }} 您好</h4>

        <md-field class="md-form-group" slot="inputs">
          <md-icon>face</md-icon>
          <label>帳號</label>
          <md-input v-model="model.email" type="account" readonly></md-input>
        </md-field>

        <md-field class="md-form-group" slot="inputs">
          <md-icon>password</md-icon>
          <label>password</label>
          <md-input v-model="model.password" type="password"></md-input>
        </md-field>
        <md-button
          slot="footer"
          class="md-simple md-success md-lg"
          @click="onSendResetPassword"
          >重設密碼</md-button
        >
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import request from "@/utils/requestGolang";

export default {
  components: {
    LoginCard
  },
  data() {
    const isDevelopment = process.env.NODE_ENV !== "development";
    let defaultData;
    if (isDevelopment) {
      defaultData = {
        model: {
          email: "",
          password: "",
          uuid: ""
        }
      };
    } else {
      defaultData = {
        model: {
          email: "",
          password: "",
          uuid: ""
        }
      };
    }
    return defaultData;
  },
  created: async function() {
    try {
      let { data } = await request.post(
        "/user/check_reset_password/",
        {
          uuid: this.$route.params.uuid
        },
        {
          headers: {
            Authorization: process.env.VUE_APP_AUTHORIZATIOH
          }
        }
      );

      if (data) {
        this.model.email = data.email;
        this.model.uuid = data.user_id;
      } else {
        this.$notify({
          timeout: 2500,
          message: "重置密碼信已失效",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "warning"
        });
        this.goLogin();
        // this.status = 2;
      }
    } catch (error) {
      this.$notify({
        timeout: 2500,
        message: "重置密碼信已失效",
        icon: "add_alert",
        horizontalAlign: "center",
        verticalAlign: "bottom",
        type: "warning"
      });
      this.goLogin();
      // this.status = 2;
    }
  },
  methods: {
    async onCheck() {},
    async onSendResetPassword() {
      try {
        let response = await request.post("/user/check_reset_password/", {
          uuid: this.$route.params.uuid,
          password: this.model.password
        });
        if (response.status == 400) {
          this.$notify({
            timeout: 2500,
            message: "重置密碼信已失效",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning"
          });
          this.goLogin();
        } else {
          this.$notify({
            timeout: 2500,
            message: "重置密碼成功，請用新密碼登入",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning"
          });
          this.goLogin();
        }
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "重置密碼信已失效",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "warning"
        });
        this.goLogin();
      }
    },
    async goLogin() {
      // await this.$store.dispatch({
      //   type: "getUserInfo"
      // });
      // window.location.href = "/admin/";
      this.$router.push({
        path: "/login"
      });
    }
  }
};
</script>

<style>
.title {
  font-weight: 400;
}
</style>
