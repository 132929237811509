<template>
  <div class="md-layout" v-if="isLoading">Loading...</div>
  <div class="md-layout" v-else>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon">
            <md-icon>highlight</md-icon>
          </div>
          <p class="category">裝置數</p>
          <h3 class="title">
            <animated-number
              :value="device_count"
              :duration="1000"
            ></animated-number>
          </h3>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>outlet</md-icon>
          </div>
          <p class="category">機型數</p>
          <h3 class="title">
            <animated-number
              :value="model_count"
              :duration="1000"
            ></animated-number>
          </h3>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>smartphone</md-icon>
          </div>
          <p class="category">使用者數</p>
          <h3 class="title">
            <animated-number
              :value="user_count"
              :duration="1000"
            ></animated-number>
          </h3>
        </template>
      </stats-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <chart-card
        :chart-data="login_ids.data"
        :chart-options="options"
        chart-type="Line"
        chart-inside-header
        background-color="rose"
      >
        <md-icon slot="fixed-button">build</md-icon>
        <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button>
        <md-button class="md-simple md-just-icon" slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Change Date</md-tooltip>
        </md-button>

        <template slot="content">
          <h4 class="title">一週登入數</h4>
        </template>
      </chart-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <chart-card
        :chart-data="new_member_ids.data"
        :chart-options="options"
        chart-type="Line"
        chart-inside-header
        background-color="green"
      >
        <md-button class="md-simple md-info md-just-icon" slot="first-button">
          <md-icon>refresh</md-icon>
          <md-tooltip md-direction="bottom">Refresh</md-tooltip>
        </md-button>
        <md-button class="md-simple md-just-icon" slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Change Date</md-tooltip>
        </md-button>

        <template slot="content">
          <h4 class="title">一週新增用戶</h4>
        </template>
      </chart-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
    >
      <chart-card
        :chart-data="bind_ids.data"
        :chart-options="options"
        chart-type="Line"
        chart-inside-header
        background-color="blue"
      >
        <template slot="content">
          <h4 class="title">一週綁定機器</h4>
        </template>
      </chart-card>
    </div>
    <div>
      <h4>後台系統: {{ backEnd }}</h4>
      <h4>前台系統: v2.1.3</h4>
    </div>
  </div>
</template>

<script>
//import AsyncWorldMap from "@/components/WorldMap/AsyncWorldMap.vue";
import {
  StatsCard,
  ChartCard,
  AnimatedNumber /*,
  GlobalSalesCard,
  GlobalSalesTable */,
} from "@/components";
import request from "@/utils/requestGolang";
export default {
  components: {
    StatsCard,
    ChartCard,
    AnimatedNumber,

    // GlobalSalesCard,
    // GlobalSalesTable,
    // AsyncWorldMap
  },
  created: function () {
    this.get_data();
  },
  methods: {
    async get_data() {
      try {
        let { data } = await request.get(`/Gweb/dashboard`);
        this.device_count = data.device_count;
        this.model_count = data.model_count;
        this.user_count = data.user_count;
        this.new_member_ids.data = {
          labels: data.new_member_ids.map((i) => i.record_at),
          series: [data.new_member_ids.map((i) => i.count)],
        };
        this.login_ids.data = {
          labels: data.login_ids.map((i) => i.record_at),
          series: [data.login_ids.map((i) => i.count)],
        };
        console.log(this.login_ids.data);
        this.bind_ids.data = {
          labels: data.bind_ids.map((i) => i.record_at),
          series: [data.bind_ids.map((i) => i.count)],
        };
        this.backEnd = data.backEnd;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          timeout: 2500,
          message: "網路連線有問題",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "warning",
        });
      }
    },
  },

  data() {
    return {
      info: this.$store.state.info,
      backEnd: "v0.0.0",
      isLoading: true,
      device_count: 30,
      model_count: 0,
      user_count: 0,
      bind_ids: {
        data: {
          labels: [],
          series: [],
        },
      },
      login_ids: {
        data: {
          labels: [],
          series: [],
        },
      },
      new_member_ids: {
        data: {
          labels: [],
          series: [],
        },
      },
      options: {
        lineSmooth: this.$Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        height: "230px",
      },

      mapData: {
        AU: 760,
        US: 120,
        CN: 1300,
        GB: 690,
        GE: 200,
        EN: 200,
        DE: 600,
        JP: 600,
        TW: 29220,
      },
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),

          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),

          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          axisX: {
            showGrid: false,
          },

          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
      },
    };
  },
};
</script>
