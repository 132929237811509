<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header> </md-card-header>
        <md-card-content>
          <md-table
            :value="searchedData"
            @md-selected="onSelectd"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="single"
            >
              <md-table-cell md-label="uuid">
                {{ item.device_id }}
              </md-table-cell>

              <md-table-cell md-label="Name">
                {{ item.display_name }}
              </md-table-cell>
              <md-table-cell md-label="MAC">
                {{ item.mac }}
              </md-table-cell>
              <md-table-cell md-label="serial">
                {{ item.serial }}
              </md-table-cell>
              <md-table-cell md-label="產品型號">
                {{ showProductModelName(item.product_model_id) }}
              </md-table-cell>
              <md-table-cell md-label="紀錄">
                <md-icon>{{ item.be_logged ? "check_circle" : "" }}</md-icon>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import request from "@/utils/requestGolang";
import HasPermission, { perm } from "@/utils/permission";

export default {
  components: {},
  created: async function () {
    this.perm.view_brand = HasPermission(this.info, perm.ViewBrand);
    if (!this.perm.view_brand) {
      this.brand_filter = this.brand_list[0].brand_id;
      this.updateProductList();
      if (this.product_list_fed[0]) {
        this.product_filter = this.product_list_fed[0].product_id;
        this.updateModelList();
      } else {
        this.product_model_fed = [];
      }
    }
    {
      let { data } = await request.get("Gweb/product_all");
      localStorage.setItem("product", JSON.stringify(data));
      this.$store.state.product = data;
      this.product_list = data;
    }
    {
      let { data } = await request.get("Gweb/product_model_list_all");
      this.product_model_list = data;
      localStorage.setItem("product_model", JSON.stringify(data));
      this.$store.state.product_model = data;
      this.product_model_fed = data;
    }
    this.requestData();
    this.selected = null;
  },
  data: function () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      search_options: [],
      search_model: null,
      searchedData: [],
      selected: null,
      product_model_list: [],
      product_model_fed: [],
      product_model_filter: null,
      brand_list: this.$store.state.brand,
      brand_filter: null,
      product_list: this.$store.state.product,
      product_list_fed: this.$store.state.product,
      product_filter: null,
      url_name: "app/user/device/",
      info: this.$store.state.info,
      perm: {
        view_brand: false,
      },
    };
  },
  props: {
    filter_query: {
      type: String,
      default: "",
    },
    url_path: {
      type: String,
      default: "",
    },
    tab_index: {
      type: String,
      default: "0",
    },
  },

  computed: {},

  methods: {
    async requestData() {
      let { data } = await request.post(this.url_name, {});
      this.searchedData = data;
    },
    showProductModelName(item) {
      let product_model_id = this.product_model_list.find(
        (i) => i.product_model_id == item
      );
      return product_model_id ? product_model_id.display_name : "";
    },
    updateProductList() {
      let s = this.brand_filter;
      let newList = this.product_list.filter(function (item, index, array) {
        return item.brand_id === s;
      });
      this.product_list_fed = newList;
    },
    updateModelList() {
      let s = this.product_filter;
      let newList = this.product_model_list.filter(function (
        item,
        index,
        array
      ) {
        return item.product_id === s;
      });
      this.product_model_fed = newList;
    },
    onTargetSelect(val) {
      this.filter.organization_id = val.organization_id;
      this.filter.organization_name = val.organization_name;
    },
    onTargetChange(value) {
      if (!value) {
        this.filter.organization_id = null;
      }
    },

    onSelectd(item) {
      this.selected = item;
      if (this.tab_index != 4)
        this.$emit("on-selected", this.tab_index, this.selected);
    },
    showBrandName(item) {
      let brand = this.brand_list.find((i) => i.brand_id == item);
      return brand ? brand.display_name : "";
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-table-head {
  background-color: lightgray !important;
}
</style>
