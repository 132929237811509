<template>
  <div class="md-layout">
    <div class="md-layout-item  md-medium-size-100">
      <md-card>
        <md-card-content>
          <ValidationObserver ref="vaccine_form">
            <form @submit.prevent="validate">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-95 ml-auto mt-4 md-small-size-100"
                >
                  <ValidationProvider
                    name=" "
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[
                        { 'md-error': failed },
                        { 'md-valid': passed },
                        { 'md-form-group': true }
                      ]"
                    >
                      <md-icon>lock</md-icon>
                      <label>請輸入舊密碼 {{ errors[0] }}</label>
                      <md-input
                        v-model="old_password"
                        type="password"
                      ></md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>

                  <ValidationProvider
                    vid="confirm"
                    name=" "
                    rules="required|checkPassword"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[
                        { 'md-error': failed },
                        { 'md-valid': passed },
                        { 'md-form-group': true }
                      ]"
                    >
                      <md-icon>lock</md-icon>
                      <label>請輸入新密碼 {{ errors[0] }}</label>
                      <md-input
                        v-model="new_password"
                        type="password"
                      ></md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name=" "
                    rules="required|confirmed:confirm"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[
                        { 'md-error': failed },
                        { 'md-valid': passed },
                        { 'md-form-group': true }
                      ]"
                    >
                      <md-icon>lock</md-icon>
                      <label>請再次輸入密碼 {{ errors[0] }}</label>
                      <md-input
                        v-model="second_password"
                        type="password"
                      ></md-input>

                      <slide-y-down-transition>
                        <md-icon class="error" v-show="failed">close</md-icon>
                      </slide-y-down-transition>
                      <slide-y-down-transition>
                        <md-icon class="success" v-show="passed">done</md-icon>
                      </slide-y-down-transition>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
            </form>
          </ValidationObserver>

          <md-card-actions>
            <md-button class="md-info" @click="onSumbit">變更</md-button>
          </md-card-actions>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { min, confirmed } from "vee-validate/dist/rules";
import { localize } from "vee-validate";
import request from "@/utils/requestGolang";
import zh_TW from "vee-validate/dist/locale/zh_TW.json";

localize("zh_TW", zh_TW);
extend("required", required => {
  return required.length === 0 ? "為必填" : true;
});

extend("checkPassword", account => {
  if (
    account.search(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/g
    ) == -1
  ) {
    return "請輸入至少1.八位數2.大小寫字母、數字、特殊符號組成的密碼";
  }
  return true;
});

extend("double_check", required => {
  return this.new_password === required ? true : "密碼不一致";
});
extend("min", min);
extend("confirmed", confirmed);

export default {
  name: "edit-profile",
  props: {},
  data: function() {
    return {
      old_password: null,
      new_password: null,
      second_password: null
    };
  },
  created: async function() {},
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    validate() {
      return this.$refs.vaccine_form.validate().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;

      reader.onload = e => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async onSumbit() {
      let isvalided = await this.validate();
      if (!isvalided) {
        return;
      }
      try {
        let { data } = await request.post(`/user/change_password/`, {
          old_password: this.old_password,
          new_password: this.new_password
        });
        this.$notify({
          timeout: 2500,
          message: "密碼變更成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: error.response.data.error,
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "warning"
        });
      }
    }
  }
};
</script>
<style></style>
