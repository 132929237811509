<template>
  <label v-if="isLoading">Loading...</label>
  <ValidationObserver ref="data_form" v-else>
    <md-button class="md-default" @click="onBack()" v-if="tab_index !== '0'"
      >返回</md-button
    >
    <form @submit.prevent="validate">
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100" v-if="tab_index == '0'">
              <md-field>
                <label for="select">過濾品牌</label>
                <md-select
                  v-model="brand_filter"
                  name=" "
                  style="padding-left:10px"
                  @input="updateProductList()"
                >
                  <md-option
                    v-for="item in brand_list"
                    :key="item.brand_id"
                    :value="item.brand_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-30">
              <ValidationProvider
                name="名稱"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[
                    { 'md-error': failed },
                    { 'md-valid': passed },
                    { 'md-form-group': true }
                  ]"
                >
                  <md-icon>account_box</md-icon>
                  <label>名稱 {{ errors[0] }}</label>
                  <md-input v-model="model.display_name" type="text"></md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-layout md-size-30">
              <md-field>
                <label for="select">Product</label>
                <md-select v-model="model.product_id" name=" ">
                  <md-option
                    v-for="item in product_list_fed"
                    :key="item.product_id"
                    :value="item.product_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30">
              <md-checkbox v-model="model.is_node">is_node</md-checkbox>
            </div>
            <div class="md-layout-item  md-size-100" v-if="tab_index !== '0'">
              <md-field>
                <md-icon>pin</md-icon>
                <label>Model ID</label>
                <md-input
                  v-model="model.product_model_id"
                  type="text"
                  readonly
                ></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div
              class="md-layout-item md-size-100"
              v-for="(part, index) in model.part_ids"
              :key="index"
            >
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <div class="md-layout">
                    <label class="md-layout-item md-size-5 md-form-label"
                      >Part</label
                    >
                    <div class="md-layout-item md-size-20">
                      <ValidationProvider
                        name="名稱"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed }
                          ]"
                        >
                          <label>Part Name {{ errors[0] }}</label>
                          <md-input
                            v-model="part.display_name"
                            type="text"
                          ></md-input>

                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed"
                              >done</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-20">
                      <md-field>
                        <label for="select">google_categories</label>
                        <md-select v-model="part.google_categories" name=" ">
                          <md-option
                            v-for="item in Cvi_Categories"
                            :key="item.v"
                            :value="item.v"
                            >{{ item.t }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                      <md-field>
                        <label for="select">alexa_categories</label>
                        <md-select v-model="part.alexa_categories" name=" ">
                          <md-option
                            v-for="item in Alexe_Categories"
                            :key="item.v"
                            :value="item.v"
                            >{{ item.t }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                      <md-field>
                        <label for="select">cvi_categories</label>
                        <md-select v-model="part.cvi_categories" name=" ">
                          <md-option
                            v-for="item in Cvi_Categories"
                            :key="item.v"
                            :value="item.v"
                            >{{ item.t }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>

                    <div class="md-layout-item md-size-10">
                      <md-button
                        class="md-just-icon md-round"
                        @click="onDeletePart(index)"
                      >
                        <md-icon>delete</md-icon>
                        <md-tooltip md-direction="bottom">刪除Part</md-tooltip>
                      </md-button>
                      <md-button
                        class="md-success md-just-icon md-round"
                        @click="onAddTrait(index)"
                      >
                        <md-icon>add</md-icon>
                        <md-tooltip md-direction="bottom">新增Trait</md-tooltip>
                      </md-button>
                    </div>
                  </div>
                </div>

                <div
                  class="md-layout-item md-size-100"
                  v-for="(trait, t_index) in part.trait_ids"
                  :key="t_index"
                >
                  <div class="md-layout">
                    <div class="md-layout-item md-size-5"></div>

                    <label class="md-layout-item md-size-5 md-form-label"
                      >Trait</label
                    >
                    <div class="md-layout-item md-size-10">
                      <ValidationProvider
                        name="Index"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed }
                          ]"
                        >
                          <label>Index {{ errors[0] }}</label>
                          <md-input
                            v-model="trait.trait_index"
                            type="text"
                          ></md-input>

                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed"
                              >done</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-10">
                      <ValidationProvider
                        name="名稱"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed }
                          ]"
                        >
                          <label>Trait Name {{ errors[0] }}</label>
                          <md-input
                            v-model="trait.display_name"
                            type="text"
                          ></md-input>

                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>

                    <div class="md-layout-item md-size-5">
                      <ValidationProvider
                        name="Max_value"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed }
                          ]"
                        >
                          <label>Max_value {{ errors[0] }}</label>
                          <md-input
                            v-model.number="trait.max_value"
                            type="number"
                          ></md-input>

                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-5">
                      <ValidationProvider
                        name="Min_value"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed }
                          ]"
                        >
                          <label>Min_value {{ errors[0] }}</label>
                          <md-input
                            v-model.number="trait.min_value"
                            type="number"
                          ></md-input>

                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-5">
                      <ValidationProvider
                        name="Step"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed }
                          ]"
                        >
                          <label>Step {{ errors[0] }}</label>
                          <md-input
                            v-model.number="trait.step_value"
                            type="number"
                          ></md-input>

                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-10">
                      <ValidationProvider
                        name=" "
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                      >
                        <md-field
                          :class="[
                            { 'md-error': failed },
                            { 'md-valid': passed }
                          ]"
                        >
                          <label>unit {{ errors[0] }}</label>
                          <md-input
                            v-model="trait.value_unit"
                            type="text"
                          ></md-input>

                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed"
                              >close</md-icon
                            >
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-10">
                      <md-field>
                        <label for="select">data_type</label>
                        <md-select v-model="trait.data_type" name=" ">
                          <md-option
                            v-for="item in TraitDataType"
                            :key="item.v"
                            :value="item.v"
                            >{{ item.t }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-10">
                      <md-field>
                        <label for="select">Permission_trait_type</label>
                        <md-select
                          v-model="trait.permission_trait_type"
                          name=" "
                        >
                          <md-option
                            v-for="item in PermissionTraitType"
                            :key="item.v"
                            :value="item.v"
                            >{{ item.t }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-20">
                      <md-field>
                        <label for="select">Traits_Type</label>
                        <md-select v-model="trait.trait_type" name=" ">
                          <md-option
                            v-for="item in Traits_Type"
                            :key="item.v"
                            :value="item.v"
                            >{{ item.t }}</md-option
                          >
                        </md-select>
                      </md-field>
                    </div>
                    <div>
                      <md-button
                        class="md-just-icon md-round"
                        @click="onDeleteTrait(index, t_index)"
                      >
                        <md-icon>delete</md-icon>
                        <md-tooltip md-direction="bottom">刪除Trait</md-tooltip>
                      </md-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <md-card-actions md-alignment="left">
            <md-button
              class="md-primary md-just-icon md-round"
              @click="onAddPart()"
            >
              <md-icon>add</md-icon>
              <md-tooltip md-direction="bottom">新增Part</md-tooltip>
            </md-button>
          </md-card-actions>
        </md-card-content>
      </md-card>

      <md-card-actions>
        <md-button class="md-success" @click="onAdd()" v-show="isNew"
          >新增</md-button
        >
        <md-button
          class="md-warning"
          @click="onEdit()"
          v-show="!isNew"
          v-if="perm.change"
          >儲存</md-button
        >
        <md-button
          class="md-danger"
          @click="onDelete()"
          v-show="!isNew"
          v-if="perm.delete"
          >刪除</md-button
        >
      </md-card-actions>
    </form>
  </ValidationObserver>
</template>
<script>
import Swal from "sweetalert2";
import request from "@/utils/requestGolang";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { localize } from "vee-validate";
import zh_TW from "vee-validate/dist/locale/zh_TW.json";
import hasPerm from "@/utils/permission";
import { perm } from "@/utils/permission";

localize("zh_TW", zh_TW);
extend("required", required);
extend("email", email);

export default {
  components: {},
  created: async function() {
    this.perm.view_brand = hasPerm(this.info, perm.ViewBrand);
    this.perm.change = hasPerm(this.info, perm.ChangeModel);
    this.perm.delete = hasPerm(this.info, perm.DeleteModel);
    if (!this.perm.view_brand) {
      this.brand_filter = this.brand_list[0].brand_id;
      this.updateProductList();
    }
    this.isLoading = true;
    if (this.select_model)
      this.getModelDetail(this.select_model.product_model_id);
    else this.isLoading = false;
  },

  data: function() {
    let now = new Date();
    return {
      isLoading: false,
      info: this.$store.state.info,
      perm: {
        change: false,
        delete: false,
        view_brand: false
      },
      brand_list: this.$store.state.brand,
      product_list: this.$store.state.product,
      product_list_fed: this.$store.state.product,
      brand_filter: null,
      url_name: "Gweb/product_model",
      Cvi_Categories: [
        { v: 0, t: "Other" },
        { v: 1, t: "Bridge" },
        { v: 2, t: "Fan" },
        { v: 3, t: "GarageDoorOpener" },
        { v: 4, t: "Lightbulb" },
        { v: 5, t: "DoorLock" },
        { v: 6, t: "Outlet" },
        { v: 7, t: "Switch" },
        { v: 8, t: "Thermostat" },
        { v: 9, t: "Sensor" },
        { v: 10, t: "SecuritySystem" },
        { v: 11, t: "Door" },
        { v: 12, t: "Window" },
        { v: 13, t: "WindowCovering" },
        { v: 14, t: "ProgrammableSwitch" },
        { v: 15, t: "IPCamera" },
        { v: 16, t: "VideoDoorbell" },
        { v: 17, t: "AirPurifier" },
        { v: 18, t: "AirHeater" },
        { v: 19, t: "AirConditioner" },
        { v: 20, t: "AirHumidifier" },
        { v: 21, t: "AirDehumidifier" },
        { v: 22, t: "LightWithColorTemperature" },
        { v: 23, t: "N/A" }
      ],
      Alexe_Categories: [
        { v: 0, t: "Other" },
        { v: 1, t: "Bridge" },
        { v: 2, t: "Fan" },
        { v: 3, t: "GarageDoorOpener" },
        { v: 4, t: "Lightbulb" },
        { v: 5, t: "DoorLock" },
        { v: 6, t: "Outlet" },
        { v: 7, t: "Switch" },
        { v: 8, t: "Thermostat" },
        { v: 9, t: "Sensor" },
        { v: 10, t: "SecuritySystem" },
        { v: 11, t: "Door" },
        { v: 12, t: "Window" },
        { v: 13, t: "WindowCovering" },
        { v: 14, t: "ProgrammableSwitch" },
        { v: 15, t: "IPCamera" },
        { v: 16, t: "VideoDoorbell" },
        { v: 17, t: "AirPurifier" },
        { v: 18, t: "AirHeater" },
        { v: 19, t: "AirConditioner" },
        { v: 20, t: "AirHumidifier" },
        { v: 21, t: "AirDehumidifier" },
        { v: 22, t: "LightWithColorTemperature" },
        { v: 23, t: "N/A" }
      ],
      TraitDataType: [
        { v: 0, t: "boolean" },
        { v: 1, t: "float" },
        { v: 2, t: "integer" },
        { v: 3, t: "string" },
        { v: 4, t: "blob" }
      ],
      PermissionTraitType: [
        { v: 0, t: "nothing" },
        { v: 1, t: "can view" },
        { v: 2, t: "can action" }
      ],
      Traits_Type: [
        { v: 0, t: "Administrator Only Access" },
        { v: 1, t: "Audio Feedback" },
        { v: 2, t: "Brightness" },
        { v: 3, t: "Cooling Threshold Temperature" },
        { v: 4, t: "Current Door State" },
        { v: 5, t: "Current Heating Cooling State" },
        { v: 6, t: "Current Relative Humidity" },
        { v: 7, t: "Cooling Temperature" },
        { v: 8, t: "Heating Threshold Temperature" },
        { v: 9, t: "Hue" },
        { v: 10, t: "Identify" },
        { v: 11, t: "Lock Control Point" },
        { v: 12, t: "Lock Management Auto Security Timeout" },
        { v: 13, t: "Lock Last Known Action" },
        { v: 14, t: "Lock Current State" },
        { v: 15, t: "Logs" },
        { v: 16, t: "Manufacturer" },
        { v: 17, t: "Model" },
        { v: 18, t: "Motion Detected" },
        { v: 19, t: "Name" },
        { v: 20, t: "Obstruction Detected" },
        { v: 21, t: "On" },
        { v: 22, t: "Outlet In Use" },
        { v: 23, t: "Rotation Direction" },
        { v: 24, t: "Rotation Speed" },
        { v: 25, t: "Saturation" },
        { v: 26, t: "Serial Number" },
        { v: 27, t: "Target Door State" },
        { v: 28, t: "Target Heating Cooling State" },
        { v: 29, t: "Target Heating Cooling State 2" },
        { v: 30, t: "Target Temperature" },
        { v: 31, t: "Temperature Display Units" },
        { v: 32, t: "Version" },
        { v: 33, t: "Firmware Revision" },
        { v: 34, t: "Hardware Revision" },
        { v: 35, t: "Air Particulate Density" },
        { v: 36, t: "Air Particulate Size" },
        { v: 37, t: "Security System Current State" },
        { v: 38, t: "Security System Target State" },
        { v: 39, t: "Battery Level" },
        { v: 40, t: "Carbon Monoxide Detected" },
        { v: 41, t: "Contract State" },
        { v: 42, t: "Current Ambient Light Level" },
        { v: 43, t: "Current Horizontal Tilt Angel" },
        { v: 44, t: "Current Position" },
        { v: 45, t: "Current Vertical Tilt Angel" },
        { v: 46, t: "Hold Position" },
        { v: 47, t: "Leak Detected" },
        { v: 48, t: "Occupancy Detected" },
        { v: 49, t: "Programmable Switch Event" },
        { v: 50, t: "Status Active" },
        { v: 51, t: "Smoke Detected" },
        { v: 52, t: "Status Fault" },
        { v: 53, t: "Status Jammed" },
        { v: 54, t: "Status Low Battery" },
        { v: 55, t: "Status Tampered" },
        { v: 56, t: "Target Horizontal Tilt Angle" },
        { v: 57, t: "Target Position" },
        { v: 58, t: "Target Vertical Tilt Angle" },
        { v: 59, t: "Security System Alarm Type" },
        { v: 60, t: "Charging State" },
        { v: 61, t: "Carbon Monoxide Level" },
        { v: 62, t: "Carbon Monoxide Peak Level" },
        { v: 63, t: "Carbon Dioxide Detected" },
        { v: 64, t: "Carbon Dioxide Level" },
        { v: 65, t: "Carbon Dioxide Peak Level" },
        { v: 66, t: "Air Quality" },
        { v: 67, t: "Accessory Flags" },
        { v: 68, t: "Lock Physical Controls" },
        { v: 69, t: "Target Air Purifier State" },
        { v: 70, t: "Current Air Purifier State" },
        { v: 71, t: "Current Slat State" },
        { v: 72, t: "Filter Life Level" },
        { v: 73, t: "Filter Change Indication" },
        { v: 74, t: "Reset Ffilter Indication" },
        { v: 75, t: "Current Fan State" },
        { v: 76, t: "Active" },
        { v: 77, t: "Current Heater Cooler State" },
        { v: 78, t: "Target Heater Cooler State" },
        { v: 79, t: "Current Humidifier Dehumidifier State" },
        { v: 80, t: "Target Humidifier Dehumidifier State" },
        { v: 81, t: "Water Level" },
        { v: 82, t: "Swing Mode" },
        { v: 83, t: "Target Fan State" },
        { v: 84, t: "Slat Type" },
        { v: 85, t: "Current Tilt Angle" },
        { v: 86, t: "Target Tilt Angle" },
        { v: 87, t: "Ozone Density" },
        { v: 88, t: "Nitrogen Dioxide Density" },
        { v: 89, t: "Sulphur Dioxide Density" },
        { v: 90, t: "PM2.5 Density" },
        { v: 91, t: "PM10 Density" },
        { v: 92, t: "VOC Density" },
        { v: 93, t: "Relative Humidity Dehumidifier Threshold" },
        { v: 94, t: "Relative Humidity Humidifier Threshold" },
        { v: 95, t: "Service Label Index" },
        { v: 96, t: "Service Label Namespace" },
        { v: 97, t: "Color Temperature" },
        { v: 98, t: "Supported Video Stream Configuration" },
        { v: 99, t: "Supported Audio Stream Configuration" },
        { v: 100, t: "Supported RTP Configuration" },
        { v: 101, t: "Selected RTP Stream Configuration" },
        { v: 102, t: "Setup Endpoints" },
        { v: 103, t: "Volume" },
        { v: 104, t: "Mute" },
        { v: 105, t: "Night Vision" },
        { v: 106, t: "Optical Zoom" },
        { v: 107, t: "Digital Zoom" },
        { v: 108, t: "Image Rotation" },
        { v: 109, t: "Image Mirroring" },
        { v: 110, t: "Stream Status" },
        { v: 111, t: "Lock Target State" },
        { v: 112, t: "Position State" },
        { v: 113, t: "ColorTemperature(Kelvin)" },
        { v: 114, t: "Current TemperatureInKelvin" },
        { v: 115, t: "Other" },
        { v: 116, t: "N/A" }
      ],
      model: {
        product_model_id: "",
        product_id: "",
        is_node: false,
        display_name: "",
        part_ids: [
          {
            alexa_categories: 0,
            cvi_categories: 0,
            display_name: "test",
            google_categories: 0,
            trait_ids: [
              {
                data_type: 0,
                display_name: "溫度",
                max_value: 100,
                min_value: 0,
                permission_trait_type: 0,
                step_value: 1,
                trait_index: "00",
                trait_type: 2,
                value_unit: "度"
              }
            ]
          }
        ]
      },
      is_node_table: [
        { title: "false", v: false },
        { title: "yes", v: true }
      ]
    };
  },
  props: {
    select_model: Object,
    tab_index: {
      type: String,
      default: "0"
    }
  },

  computed: {
    isNew() {
      return this.model.product_model_id.length === 0;
    }
  },
  methods: {
    updateProductList() {
      let s = this.brand_filter;
      let newList = this.product_list.filter(function(item, index, array) {
        return item.brand_id === s;
      });
      this.product_list_fed = newList;
    },
    validate() {
      return this.$refs.data_form.validate().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
    onBack() {
      this.$emit("on-back", this.tab_index);
    },
    // todo permission

    async onAdd() {
      let isvalided = await this.validate();
      if (isvalided) {
        this.model.part_ids.forEach(part_id => {
          let repeat = part_id.trait_ids.filter(function(element, index, arr) {
            return arr.indexOf(element) !== index;
          });
        });

        try {
          let req = this.model;
          delete req["product_model_id"];
          let { data } = await request.post(`/${this.url_name}`, {
            ...req
          });
          //this.model.user_id = "have";
          this.$notify({
            timeout: 2500,
            message: "創建成功",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success"
          });
          this.$router.go();
        } catch (error) {
          this.$notify({
            timeout: 2500,
            message: "error",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success"
          });
        }
      }
    },
    async onEdit() {
      for (let index = 0; index < this.model.part_ids.length; index++) {
        const part_id = this.model.part_ids[index];
        // Array.from(new Set(origin))
        let repeat = Array.from(
          new Set(part_id.trait_ids.map(i => i.trait_index))
        );
        if (repeat.length != part_id.trait_ids.length) {
          this.$notify({
            timeout: 2500,
            message: "trait_index 重複",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success"
          });
          return;
        }
      }

      // Test Update
      try {
        await request.put(`/${this.url_name}`, {
          ...this.model
        });
        this.$notify({
          timeout: 2500,
          message: "修改成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
        this.$emit("on-back", this.tab_index);
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
      }
    },
    async onDelete() {
      Swal.fire({
        title: "確定要刪除嗎?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        buttonsStyling: false
      }).then(async result => {
        if (result.dismiss) {
          return;
        }
        await this.onDeleteApi();
        this.$emit("on-back", this.tab_index);
      });
    },
    async onDeleteApi() {
      try {
        let { data } = await request.delete(
          `/${this.url_name}/${this.model.product_model_id}`,
          {}
        );
        this.$notify({
          timeout: 2500,
          message: "刪除成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
        this.$emit("on-back", this.tab_index);
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
      }
    },
    onAddPart() {
      this.model.part_ids.push({
        alexa_categories: 0,
        cvi_categories: 0,
        display_name: "",
        google_categories: 0,
        trait_ids: []
      });
    },
    onAddTrait(index) {
      this.model.part_ids[index].trait_ids.push({
        data_type: 0,
        display_name: "",
        max_value: 100,
        min_value: 0,
        permission_trait_type: 0,
        step_value: 1,
        trait_index: "",
        trait_type: 2,
        value_unit: ""
      });
    },
    onDeletePart(index) {
      this.model.part_ids.splice(index, 1);
    },
    onDeleteTrait(part_index, trait_index) {
      this.model.part_ids[part_index].trait_ids.splice(trait_index, 1);
    },
    async getModelDetail(model_ID) {
      var searchParams = new URLSearchParams("");
      searchParams.append("model", model_ID);
      let { data } = await request.get(
        `/Gweb/product_model_detail?${searchParams.toString()}`
      );
      this.model = data;
      this.isLoading = false;
    }
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    }
  }
};
</script>

<style lang="css" scoped>
.error.md-icon {
  right: 20px;
}
.success.md-icon {
  right: 20px;
}
</style>
