<template>
  <div class="md-layout">
    <div class="md-layout-item mx-auto md-medium-size-100">
      <tabs
        :tab-name="tabName"
        :tab-icon="tabIcon"
        :tab-show="tabShow"
        :tab-count="tabCount"
        :tab-init-index="initIndex"
        :mPanel="panel"
        class="page-subcategories"
        nav-pills-icons
        color-button="success"
        @on-switchPanel="onSwitchPanel"
      >
        <template slot="tab-pane-1">
          <ModelAdd
            v-if="select[1]"
            :select_model="select[1]"
            tab_index="1"
            @on-back="onBack"
          ></ModelAdd>
          <ModelView v-else tab_index="1" @on-selected="onSelect"></ModelView>
        </template>
        <template slot="tab-pane-2">
          <ModelAdd @on-back="onBack"> </ModelAdd>
        </template>
      </tabs>
    </div>
  </div>
</template>
<script>
import { Tabs } from "@/components";
import ModelAdd from "./Add";
import ModelView from "./View";
import Vue from "vue";
export default {
  components: {
    Tabs,
    ModelAdd,
    ModelView
  },
  computed: {},
  data: function() {
    return {
      tabIcon: ["edit", "add"],
      tabName: ["查看", "新增"],
      initIndex: 0,
      tabShow: [true, true],
      select: [null, null],
      panel: "",
      tabCount: [0, 0, 0, 0, 0, 0, 0, 0],
      info: this.$store.state.info
    };
  },
  created() {},
  methods: {
    onSwitchPanel(tab_index) {
      Vue.set(this.select, tab_index, null);
    },
    onBack(tab_index) {
      this.panel = this.tabName[0];
      Vue.set(this.select, tab_index, null);
    },
    onSelect(tab_index, model) {
      Vue.set(this.select, tab_index, model);
    }
  },
  computed: {},
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    }
  }
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
