<template>
  <div class="md-layout text-center">
    <notifications></notifications>
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <login-card header-color="green">
        <h3 slot="title" class="title">CviCloud</h3>
        <h4 slot="buttons" class="title">忘記密碼</h4>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>email</label>
          <md-input v-model="model.email" type="text"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>face</md-icon>
          <label for="select">廠牌</label>
          <md-select v-model="model.brand_id" name=" ">
            <md-option
              v-for="item in brand_list"
              :key="item.brand_id"
              :value="item.brand_id"
              >{{ item.display_name }}</md-option
            >
          </md-select>
        </md-field>
        <md-button
          slot="footer"
          class="md-simple md-success md-lg"
          @click="onLogin"
          >送出</md-button
        >
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";

import axios from "axios";
import request from "@/utils/requestGolang";
export default {
  components: {
    LoginCard
  },
  data() {
    let defaultData = {
      model: {
        account: null,
        email: null,
        brand_id: null
      }
    };

    return {
      status: 1,
      model: defaultData,
      brand_list: []
    };
  },
  created: async function() {
    try {
      let { data } = await request.get("/Gweb/brand_all", {
        headers: {
          Authorization: process.env.VUE_APP_AUTHORIZATIOH
        }
      });
      this.brand_list = data;
      return;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async onLogin() {
      try {
        let response = await request.post("/app/user/forget_password", {
          ...this.model
        });
        if (response.status == 200) {
          this.$notify({
            timeout: 2500,
            message: "密碼寄送成功",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning"
          });
          this.goLogin();
        } else {
          this.$notify({
            timeout: 2500,
            message: "密碼寄出失敗",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning"
          });
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          timeout: 2500,
          message: "密碼寄出失敗",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "warning"
        });
      }
    },
    async goLogin() {
      // await this.$store.dispatch({
      //   type: "getUserInfo"
      // });
      // window.location.href = "/admin/";
      this.$router.push({
        path: "/login"
      });
    }
  }
};
</script>

<style>
.title {
  font-weight: 400;
}
</style>
