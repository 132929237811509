<template>
  <ValidationObserver ref="data_form">
    <md-button class="md-default" @click="onBack()" v-if="tab_index !== '0'"
      >返回</md-button
    >
    <form @submit.prevent="validate" v-if="tab_index == '0'">
      <md-card>
        <md-card-header>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-30">
              <md-field>
                <label for="select">過濾品牌以選擇型號</label>
                <md-select
                  v-model="brand_filter"
                  name=" "
                  style="padding-left: 10px"
                  @input="updateProductList()"
                >
                  <md-option
                    v-for="item in brand_list"
                    :key="item.brand_id"
                    :value="item.brand_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30 md-small-size-100">
              <md-field>
                <label for="select">過濾產品以選擇型號</label>
                <md-select
                  v-model="product_filter"
                  name=" "
                  style="padding-left: 10px"
                  @input="updateModelList()"
                >
                  <md-option
                    v-for="item in product_list_fed"
                    :key="item.product_id"
                    :value="item.product_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30 md-small-size-100">
              <md-field>
                <label for="select">過濾型號</label>
                <md-select
                  v-model="product_model_filter"
                  name=" "
                  @input="updateFirmwareList()"
                >
                  <md-option
                    v-for="item in product_model_fed"
                    :key="item.product_model_id"
                    :value="item.product_model_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-layout md-size-30 md-small-size-100">
              <md-field>
                <label for="select">選擇韌體</label>
                <md-select v-model="firmware_selected" name=" ">
                  <md-option
                    v-for="item in firmware_list"
                    :key="item.firmware_id"
                    :value="item.firmware_id"
                    >{{ item.display_veriosn }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-60">
              <md-field>
                <label>Firmware</label>
                <md-input :value="firmware_selected" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>name</label>
                <md-input v-model="model.display_name" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>serial</label>
                <md-input v-model="model.serial" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>mac</label>
                <md-input v-model="model.mac" type="text"></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card-actions>
        <md-button class="md-success" @click="onAdd()" v-show="isNew"
          >新增</md-button
        >
        <md-button class="md-warning" @click="onEdit()" v-show="!isNew"
          >儲存</md-button
        >
        <md-button class="md-danger" @click="onDelete()" v-show="!isNew"
          >刪除</md-button
        >
      </md-card-actions>
      <md-card v-if="add_response_flag">
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <h3><em>裝置訊息</em> （注意！離開頁面將會清除資訊）</h3>
            </div>
            <div class="md-layout-item md-layout md-size-50 md-small-size-100">
              <label>CertificatePem</label>
              <md-field>
                <md-textarea
                  v-model="this.add_response.certificatePem"
                ></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-50 md-small-size-100">
              <label>PrivateKey</label>
              <md-field>
                <md-textarea
                  v-model="this.add_response.PrivateKey"
                ></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100">
              <md-field>
                <label>Device ID</label>
                <md-input
                  v-model="this.add_response.device_id"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100">
              <md-field>
                <label>Endpoint Address</label>
                <md-input
                  v-model="this.add_response.endpoint_address"
                  type="text"
                ></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
    <form @submit.prevent="validate" v-else>
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-90" v-if="tab_index !== '0'">
              <md-field>
                <md-icon>pin</md-icon>
                <label>Device ID</label>
                <md-input
                  v-model="model.device_id"
                  type="text"
                  readonly
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>name</label>
                <md-input v-model="model.display_name" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>serial</label>
                <md-input v-model="model.serial" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>mac</label>
                <md-input v-model="model.mac" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-30">
              <md-field>
                <label for="select">過濾品牌以選擇型號</label>
                <md-select
                  v-model="brand_filter"
                  name=" "
                  style="padding-left: 10px"
                  @input="updateProductList()"
                >
                  <md-option
                    v-for="item in brand_list"
                    :key="item.brand_id"
                    :value="item.brand_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30 md-small-size-100">
              <md-field>
                <label for="select">過濾產品以選擇型號</label>
                <md-select
                  v-model="product_filter"
                  name=" "
                  style="padding-left: 10px"
                  @input="updateModelList()"
                >
                  <md-option
                    v-for="item in product_list_fed"
                    :key="item.product_id"
                    :value="item.product_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30 md-small-size-100">
              <md-field>
                <label for="select">型號</label>
                <md-select
                  v-model="product_model_filter"
                  name=" "
                  @input="updateFirmwareList()"
                >
                  <md-option
                    v-for="item in product_model_fed"
                    :key="item.product_model_id"
                    :value="item.product_model_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-30 md-small-size-100">
              <md-field>
                <label for="select">韌體</label>
                <md-select
                  v-model="firmware_selected"
                  name=" "
                  @input="selectFirmware()"
                >
                  <md-option
                    v-for="item in firmware_list"
                    :key="item.firmware_id"
                    :value="item.firmware_id"
                    >{{ item.display_veriosn }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-60">
              <md-field>
                <label>Firmware</label>
                <md-input :value="firmware_selected" type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-field>
                <label>Owner</label>
                <md-input
                  :value="model.owner ? model.owner.email : ``"
                  type="text"
                  readonly
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-30">
              <md-checkbox v-model="model.be_logged">啟用紀錄</md-checkbox>
            </div>
            <div class="md-layout-item md-size-30" v-if="model.owner">
              <md-checkbox v-model="unbind">解除綁定</md-checkbox>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card-actions>
        <md-button class="md-success" @click="onAdd()" v-show="isNew"
          >新增</md-button
        >
        <md-button
          class="md-warning"
          @click="onEdit()"
          v-show="!isNew"
          v-if="perm.change"
          >儲存</md-button
        >
        <md-button
          class="md-info"
          @click="onRenew()"
          v-show="!isNew"
          v-if="perm.add"
          >更新憑證</md-button
        >
        <md-button
          class="md-danger"
          @click="onDelete()"
          v-show="!isNew"
          v-if="perm.delete"
          >刪除</md-button
        >
      </md-card-actions>
      <md-card v-if="add_response_flag">
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <h3><em>裝置訊息</em> （注意！離開頁面將會清除資訊）</h3>
            </div>
            <div class="md-layout-item md-layout md-size-50 md-small-size-100">
              <label>CertificatePem</label>
              <md-field>
                <md-textarea
                  v-model="this.add_response.certificatePem"
                ></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-layout md-size-50 md-small-size-100">
              <label>PrivateKey</label>
              <md-field>
                <md-textarea
                  v-model="this.add_response.PrivateKey"
                ></md-textarea>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100">
              <md-field>
                <label>Device ID</label>
                <md-input
                  v-model="this.add_response.device_id"
                  type="text"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100">
              <md-field>
                <label>Endpoint Address</label>
                <md-input
                  v-model="this.add_response.endpoint_address"
                  type="text"
                ></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import Swal from "sweetalert2";
import request from "@/utils/requestGolang";
import { extend, localize } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import zh_TW from "vee-validate/dist/locale/zh_TW.json";
import hasPerm, { perm } from "@/utils/permission";

localize("zh_TW", zh_TW);
extend("required", required);
extend("email", email);

export default {
  components: {},
  created: async function () {
    this.perm.view_brand = hasPerm(this.info, perm.ViewBrand);
    this.perm.change = hasPerm(this.info, perm.ChangeDevice);
    this.perm.delete = hasPerm(this.info, perm.DeleteDevice);
    this.perm.add = hasPerm(this.info, perm.AddDevice);
    if (!this.perm.view_brand) {
      this.brand_filter = this.brand_list[0].brand_id;
      this.updateProductList();
      if (this.product_list_fed[0]) {
        this.product_filter = this.product_list_fed[0].product_id;
        this.updateModelList();
      } else {
        this.product_model_fed = [];
      }
    }
    this.isLoading = false;
    if (this.select_model) {
      this.model = this.select_model;
      this.product_model_filter = this.select_model.product_model_id;
      this.firmware_selected = this.select_model.firmware_id;
      this.updateFirmwareList();
    }
  },

  data: function () {
    return {
      isLoading: false,
      info: this.$store.state.info,
      perm: {
        change: false,
        delete: false,
        add: false,
        view_brand: false,
      },
      product_model_list: this.$store.state.product_model,
      product_model_fed: this.$store.state.product_model,
      product_model_filter: null,
      brand_list: this.$store.state.brand,
      brand_filter: null,
      product_list: this.$store.state.product,
      product_list_fed: this.$store.state.product,
      product_filter: null,
      firmware_list: [],
      firmware_selected: null,
      url_name: "Gweb/device",
      permission_name: "deviceinfo",
      model: {
        // at_gateway_index: "",
        // aws_arn: "",
        // aws_id: "",
        device_id: "",
        //devices_region_id: "",
        display_name: "",
        //endpoint_address: "",
        firmware_id: null,
        firmware_version: 1,
        // full_gateway_index: "",
        // gateway_id: null,
        // is_need_update: false,
        mac: "",
        owner_id: null,
        owner: {
          email: "",
        },
        product_model_id: null,
        // region_name: "",
        // room_id: null,
        serial: "",
        /* service_host_type: 0,
        top_gateway_id: null */
        be_logged: false,
      },
      add_response_flag: false,
      add_response: {
        certificatePem: "",
        certificateArn: "",
        certificateId: "",
        PrivateKey: "",
        device_id: "",
        endpoint_address: "",
      },
      unbind: false,
    };
  },
  props: {
    select_model: Object,
    tab_index: {
      type: String,
      default: "0",
    },
  },

  computed: {
    isNew() {
      return this.model.device_id.length === 0;
    },
  },
  methods: {
    validate() {
      return this.$refs.data_form.validate().then((res) => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
    updateProductList() {
      let s = this.brand_filter;
      let newList = this.product_list.filter(function (item, index, array) {
        return item.brand_id === s;
      });
      this.product_list_fed = newList;
    },
    updateModelList() {
      let s = this.product_filter;
      let newList = this.product_model_list.filter(function (
        item,
        index,
        array
      ) {
        return item.product_id === s;
      });
      this.product_model_fed = newList;
    },
    async updateFirmwareList() {
      let { data } = await request.get(
        "Gweb/firmware_all?model=" + this.product_model_filter
      );
      this.firmware_list = data;
    },
    selectFirmware() {
      this.model.product_model_id = this.product_model_filter;
      this.model.firmware_id = this.firmware_selected;
      let firmware = this.firmware_list.find(
        (i) => i.firmware_id == this.firmware_selected
      );
      this.model.firmware_version = firmware.int_version;
    },
    onBack() {
      this.$emit("on-back", this.tab_index);
    },
    // todo permission
    async onAdd() {
      let isvalided = await this.validate();
      if (isvalided) {
        try {
          let { data } = await request.post(`/factory/device/create`, {
            mac: this.model.mac,
            fireware_id: this.firmware_selected,
            name: this.model.display_name,
            serial: this.model.serial,
          });
          this.add_response = data;
          this.add_response_flag = true;
          this.model.user_id = "have";
          this.$notify({
            timeout: 2500,
            message: "創建成功",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success",
          });
        } catch (error) {
          this.$notify({
            timeout: 2500,
            message: "error",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success",
          });
        }
      }
    },
    async onEdit() {
      try {
        let pushData = { ...this.model };
        delete pushData.owner;
        delete pushData.firmware;
        pushData["unbind"] = this.unbind;
        /*let { data } =*/ await request.put(
          `/${this.url_name}/${this.model.device_id}`,
          pushData
        );
        this.$notify({
          timeout: 2500,
          message: "修改成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
      }
    },
    async onDelete() {
      Swal.fire({
        title: "確定要刪除嗎?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.dismiss) {
          return;
        }
        await this.onDeleteApi();
        this.$emit("on-back", this.tab_index);
      });
    },
    async onDeleteApi() {
      try {
        /*let { data } =*/ await request.delete(
          `/${this.url_name}/${this.model.device_id}`,
          {}
        );
        this.$notify({
          timeout: 2500,
          message: "刪除成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
        this.$emit("on-back", this.tab_index);
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
      }
    },
    async onRenew() {
      Swal.fire({
        title: "確定要更新憑證嗎?舊的憑證將無法使用",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.dismiss) {
          return;
        }
        await this.onRenewApi();
      });
    },
    async onRenewApi() {
      try {
        let { data } = await request.put(
          `/${this.url_name}/cert/${this.model.device_id}`,
          {}
        );
        this.add_response = data;
        this.add_response_flag = true;
        this.$notify({
          timeout: 2500,
          message: "更新成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success",
        });
        console.log(error);
      }
    },
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    },
  },
};
</script>

<style lang="css" scoped>
.error.md-icon {
  right: 20px;
}
.success.md-icon {
  right: 20px;
}
</style>
