<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive }
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <template slot="links">
        <sidebar-item
          :link="{ name: '首頁', icon: 'dashboard', path: '/dashboard' }"
        >
        </sidebar-item>
        <sidebar-item :link="{ name: '會員管理', icon: 'image' }">
          <sidebar-item
            v-if="info.is_staff && perm.view_users"
            :link="{ name: '會員', path: '/appcore/user' }"
          ></sidebar-item>
          <sidebar-item
            v-if="(info.is_staff && perm.view_home) || info.is_super_user"
            :link="{ name: 'Home', path: '/appcore/home' }"
          ></sidebar-item>
          <sidebar-item
            v-if="(info.is_staff && perm.view_room) || info.is_super_user"
            :link="{ name: 'Room', path: '/appcore/room' }"
          ></sidebar-item>
          <sidebar-item
            v-if="(info.is_staff && perm.view_zone) || info.is_super_user"
            :link="{ name: 'Zone', path: '/appcore/zone' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: '裝置控制', icon: 'image' }">
          <sidebar-item
            v-if="info.is_superuser"
            :link="{ name: 'Automation', path: '/user/automation' }"
          ></sidebar-item>
          <sidebar-item
            v-if="info.is_superuser"
            :link="{ name: 'Devices', path: '/user/devices' }"
          ></sidebar-item>
          <sidebar-item
            v-if="info.is_superuser"
            :link="{ name: 'Share', path: '/user/share' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: '商品管理', icon: 'apps' }">
          <sidebar-item
            v-if="(info.is_staff && perm.view_brand) || info.is_super_user"
            :link="{ name: 'Brand', path: '/deivces/brand' }"
          ></sidebar-item>
          <sidebar-item
            v-if="(info.is_staff && perm.view_product) || info.is_super_user"
            :link="{ name: 'Product', path: '/deivces/product' }"
          ></sidebar-item>
          <sidebar-item
            v-if="(info.is_staff && perm.view_model) || info.is_super_user"
            :link="{ name: 'Model', path: '/deivces/product_model' }"
          ></sidebar-item>
          <sidebar-item
            v-if="(info.is_staff && perm.view_firmware) || info.is_super_user"
            :link="{ name: 'Firmware', path: '/deivces/firmware' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ name: '裝置管理', icon: 'devices' }">
          <sidebar-item
            v-if="(info.is_staff && perm.view_device) || info.is_super_user"
            :link="{ name: 'Device', path: '/deivces/device' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: '尿布感測管理', icon: 'baby_changing_station' }"
        >
          <sidebar-item
            v-if="(info.is_staff && perm.view_baby) || info.is_super_user"
            :link="{ name: 'Baby', path: '/diaper/baby' }"
          ></sidebar-item>
          <sidebar-item
            v-if="(info.is_staff && perm.view_old) || info.is_super_user"
            :link="{ name: 'Old', path: '/diaper/old' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'Log', icon: 'devices' }">
          <sidebar-item
            v-if="(info.is_staff && perm.view_device) || info.is_super_user"
            :link="{ name: 'Log', path: '/log' }"
          ></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <router-view></router-view>
        </zoom-center-transition>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import hasPerm from "@/utils/permission";
import { perm } from "@/utils/permission";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";

export default {
  components: {
    TopNavbar

    // MobileMenu
  },
  created: async function() {
    if (!this.$store.state.golangToken) {
      this.$router.push({
        path: "/login"
      });
      return;
    }
    this.perm.view_users = hasPerm(this.info, perm.ViewUsers);
    this.perm.view_baby = hasPerm(this.info, perm.ViewBaby);
    this.perm.view_old = hasPerm(this.info, perm.ViewOld);
    this.perm.view_brand = hasPerm(this.info, perm.ViewBrand);
    this.perm.view_product = hasPerm(this.info, perm.ViewProduct);
    this.perm.view_model = hasPerm(this.info, perm.ViewModel);
    this.perm.view_firmware = hasPerm(this.info, perm.ViewFirmware);
    this.perm.view_device = hasPerm(this.info, perm.ViewDevice);
    this.perm.view_home = hasPerm(this.info, perm.ViewHome);
    this.perm.view_room = hasPerm(this.info, perm.ViewRoom);
    this.perm.view_zone = hasPerm(this.info, perm.ViewZone);
  },
  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: "./img/sidebar-2.jpg",
      sidebarMini: false,
      sidebarImg: true,
      info: this.$store.state.info,
      perm: {
        view_users: false,
        view_brand: false,
        view_product: false,
        view_model: false,
        view_firmware: false,
        view_device: false,
        view_baby: false,
        view_old: false,
        view_home: false,
        view_room: false,
        view_zone: false
      }
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    }
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
