<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header>
          <div class="md-layout">
            <div class="md-layout-item  md-small-size-100 md-size-33">
              <md-autocomplete
                class="search"
                v-model="search_model"
                :md-options="search_options"
                @md-selected="onTargetSelect"
                @md-changed="onTargetChange"
              >
                <label>搜尋名稱</label>
                <template slot="md-autocomplete-item" slot-scope="{ item }">{{
                  item
                }}</template>
              </md-autocomplete>
            </div>
            <div class="md-layout-item  md-small-size-100 md-size-33">
              <md-field>
                <label for="select">過濾品牌</label>
                <md-select
                  v-model="brand_filter"
                  name=" "
                  style="padding-left:10px"
                >
                  <md-option
                    v-for="item in brand_list"
                    :key="item.brand_id"
                    :value="item.brand_id"
                    >{{ item.display_name }}</md-option
                  >
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="searchedData"
            @md-selected="onSelectd"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="single"
            >
              <md-table-cell md-label="名稱">
                {{ item.display_name }}
              </md-table-cell>
              <md-table-cell md-label="Product">
                {{ showProductName(item.product_id) }}
              </md-table-cell>
              <md-table-cell md-label="is_node">
                {{ item.is_node }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              第 {{ from + 1 }} 到 {{ to }} 項目 於 {{ total }} 表單
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :pageCount="total"
          ></pagination>
        </md-card-actions>
        <md-card-actions> </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination } from "@/components";
import request from "@/utils/requestGolang";
import moment from "moment";

export default {
  components: {
    Pagination
  },
  created: async function() {
    {
      let { data } = await request.get("Gweb/product_all");
      localStorage.setItem("product", JSON.stringify(data));
      this.$store.state.product = data;
      this.product_list = data;
    }
    this.requestData();
    this.selected = null;
  },
  data: function() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      search_options: [],
      search_model: null,
      searchedData: [],
      selected: null,
      brand_list: this.$store.state.brand,
      product_list: this.$store.state.product,
      url_name: "Gweb/product_model_list",
      brand_filter: null
    };
  },
  props: {
    filter_query: {
      type: String,
      default: ""
    },
    url_path: {
      type: String,
      default: ""
    },
    tab_index: {
      type: String,
      default: "0"
    }
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    query_url: function() {
      var searchParams = new URLSearchParams(this.filter_query);
      searchParams.append("pagesize", this.pagination.perPage);

      if (this.pagination.currentPage) {
        searchParams.append("page", this.pagination.currentPage);
      }
      if (this.search_model) {
        searchParams.append("search", this.search_model);
      }
      if (this.brand_filter) {
        searchParams.append("brand", this.brand_filter);
      }
      var url = `/${this.url_name}?${searchParams.toString()}`;

      return url;
    }
  },

  methods: {
    async requestData() {
      let { data } = await request.get(this.query_url);
      this.searchedData = data.data;
      this.pagination.total = data.total;
      this.pagination.perPage = data.per_page;
      this.pagination.currentPage = data.current_page;
    },
    onTargetSelect(val) {
      this.filter.organization_id = val.organization_id;
      this.filter.organization_name = val.organization_name;
    },
    onTargetChange(value) {
      if (!value) {
        this.filter.organization_id = null;
      }
    },

    onSelectd(item) {
      this.selected = item;
      if (this.tab_index != 4)
        this.$emit("on-selected", this.tab_index, this.selected);
    },
    showProductName(item) {
      let product = this.product_list.find(i => i.product_id == item);
      return product ? product.display_name : "";
    },
    async getModelDetail(model_ID) {
      var searchParams = new URLSearchParams("");
      searchParams.append("model", model_ID);
      let { data } = await request.get(
        `/Gweb/product_model_detail?${searchParams.toString()}`
      );
      return data.data;
    }
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    },
    search_model: async function(newData, oldData) {
      if (newData != null) {
        if (this.brand_filter)
          newData = newData + `&brand=` + this.brand_filter;
        try {
          let { data } = await request.get(
            `/${this.url_name}?search=${newData}`
          );
          this.searchedData = data.data;
          this.pagination.total = data.total;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
        } catch (error) {
          this.$notify({
            timeout: 2500,
            message: "網路連線有問題",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning"
          });
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-table-head {
  background-color: lightgray !important;
}
</style>
