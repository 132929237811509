import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token:
            (localStorage.getItem("token") &&
                JSON.parse(localStorage.getItem("token"))) ||
            "",
        golangToken:
            (localStorage.getItem("golangToken") &&
                JSON.parse(localStorage.getItem("golangToken"))) ||
            "",
        info:
            (localStorage.getItem("info") &&
                JSON.parse(localStorage.getItem("info"))) ||
            "",
        brand:
            (localStorage.getItem("brand") &&
                JSON.parse(localStorage.getItem("brand"))) ||
            "",
        product:
            (localStorage.getItem("product") &&
                JSON.parse(localStorage.getItem("product"))) ||
            "",
        product_model:
            (localStorage.getItem("product_model") &&
                JSON.parse(localStorage.getItem("product_model"))) ||
            ""
    },
    mutations: {},
    actions: {}
});