<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-content>
          <md-table
            :value="searchedData"
            @md-selected="onSelectd"
            class="paginated-table table-striped table-hover"
          >
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="single"
            >
              <md-table-cell md-label="名稱">
                {{ item.display_name }}
              </md-table-cell>
              <md-table-cell md-label="Room Type">
                {{ item.room_type }}
              </md-table-cell>

              <md-table-cell md-label="Home">
                {{ item.home_id ? item.home_id.display_name : `` }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              第 {{ from + 1 }} 到 {{ to }} 表單 共 {{ total }} 表單
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
        <md-card-actions> </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination } from "@/components";
import request from "@/utils/requestGolang";
import moment from "moment";

export default {
  components: {
    Pagination
  },
  created: async function() {
    this.requestData();
    this.selected = null;
  },
  data: function() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      search_options: [],
      search_model: null,
      searchedData: [],
      selected: null,
      brand_list: this.$store.state.brand,
      url_name: "room_list"
    };
  },
  props: {
    filter_query: {
      type: String,
      default: ""
    },
    url_path: {
      type: String,
      default: ""
    },
    tab_index: {
      type: String,
      default: "0"
    }
  },

  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
    query_url: function() {
      var searchParams = new URLSearchParams(this.filter_query);

      if (this.pagination.currentPage) {
        searchParams.append("page", this.pagination.currentPage);
      }
      var url = `/${this.url_name}/${this.url_path}?${searchParams.toString()}`;

      return url;
    }
  },

  methods: {
    async requestData() {
      let { data } = await request.get(this.query_url);
      this.searchedData = data.data;
      this.pagination.total = data.total;
      this.pagination.perPage = data.per_page;
      this.pagination.currentPage = data.current_page;
    },
    onTargetSelect(val) {
      this.filter.organization_id = val.organization_id;
      this.filter.organization_name = val.organization_name;
    },
    onTargetChange(value) {
      if (!value) {
        this.filter.organization_id = null;
      }
    },

    onSelectd(item) {
      this.selected = item;
      if (this.tab_index != 4)
        this.$emit("on-selected", this.tab_index, this.selected);
    },
    showBrandName(item) {
      let brand = this.brand_list.find(i => i.brand_id == item);
      return brand ? brand.display_name : "";
    }
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    },
    search_model: async function(newData, oldData) {
      if (newData != null) {
        try {
          let { data } = await request.get(`/uset_list/?search=${newData}`);
          this.searchedData = data.data;
          this.pagination.total = data.total;
          this.pagination.perPage = data.per_page;
          this.pagination.currentPage = data.current_page;
        } catch (error) {
          this.$notify({
            timeout: 2500,
            message: "網路連線有問題",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "warning"
          });
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-table-head {
  background-color: lightgray !important;
}
</style>
