import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";

import UserDashboard from "@/pages/Dashboard/User/Dashboard.vue";
import HomeDashboard from "@/pages/Member/Home/Dashboard.vue";
import RoomDashboard from "@/pages/Member/Room/Dashboard.vue";
import ZoneDashboard from "@/pages/Member/Zone/Dashboard.vue";

import BrandDashboard from "@/pages/Dashboard/Brand/Dashboard.vue";
import ProductDashboard from "@/pages/Dashboard/Product/Dashboard.vue";
import ProductModelDashboard from "@/pages/Dashboard/ProductModel/Dashboard.vue";
import DeviceDashboard from "@/pages/Dashboard/Device/Dashboard.vue";
import Firmwareboard from "@/pages/Dashboard/Firmware/Dashboard.vue";

import DiaperBabyDashboard from "@/pages/Dashboard/DiaperBaby/Dashboard.vue";
import DiaperOldDashboard from "@/pages/Dashboard/DiaperOld/Dashboard.vue";

import Login from "@/pages/Login/Login.vue";
import AuthLayout from "@/pages/Login/AuthLayout.vue";
import ForgetPassword from "@/pages/Login/ForgetPassword.vue";
import ResetPassswrod from "@/pages/Login/ResetPassswrod.vue";
import ChangePassword from "@/pages/Dashboard/User/ChangePassword.vue";

import UserAutomation from "@/pages/UserControl/Automation/Dashboard.vue";
import UserDevices from "@/pages/UserControl/Devices/Dashboard.vue";
import UserShare from "@/pages/UserControl/Share/Dashboard.vue";

import Log from "@/pages/Dashboard/Log/Dashboard.vue";

let deviceMenu = {
  path: "/deivces",
  component: DashboardLayout,
  name: "Device",
  redirect: "/deivces/brand",
  children: [
    {
      path: "brand",
      name: "Brand",
      components: { default: BrandDashboard }
    },
    {
      path: "product",
      name: "Product",
      components: { default: ProductDashboard }
    },
    {
      path: "product_model",
      name: "ProductModel",
      components: { default: ProductModelDashboard }
    },
    // {
    //   path: "part",
    //   name: "PartTable",
    //   components: { default: PartTable }
    // },
    // {
    //   path: "trait",
    //   name: "TraitTable",
    //   components: { default: TraitTable }
    // },
    {
      path: "firmware",
      name: "FirmwareTable",
      components: { default: Firmwareboard }
    },
    {
      path: "device",
      name: "DeviceTable",
      components: { default: DeviceDashboard }
    }
  ]
};

let userMenu = {
  path: "/appcore",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/appcore",
  children: [
    {
      path: "/appcore/user",
      name: "User",
      components: { default: UserDashboard }
    },
    {
      path: "/appcore/home",
      name: "Home",
      components: { default: HomeDashboard }
    },
    {
      path: "/appcore/room",
      name: "Room",
      components: { default: RoomDashboard }
    },
    {
      path: "/appcore/zone",
      name: "Zone",
      components: { default: ZoneDashboard }
    },

    {
      path: "/appcore/change_password",
      name: "設定密碼",
      components: { default: ChangePassword }
    }
  ]
};

let userControl = {
  path: "/user",
  component: DashboardLayout,
  name: "Control",
  redirect: "/user",
  children: [
    {
      path: "/user/automation",
      name: "Automation",
      components: { default: UserAutomation }
    },
    {
      path: "/user/devices",
      name: "UserDevice",
      components: { default: UserDevices }
    },
    {
      path: "/user/share",
      name: "Share",
      components: { default: UserShare }
    }
  ]
};

// 帳號
let authPages = {
  path: "/",
  redirect: "/login",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      components: { default: Login }
    },

    {
      path: "/forget_password",
      name: "ForgetPassword",
      components: { default: ForgetPassword }
    },
    {
      path: "/reset_password/:uuid/",
      name: "ResetPassword",
      components: { default: ResetPassswrod }
    }
  ]
};

// 尿布
let diaperMenu = {
  path: "/diaper",
  component: DashboardLayout,
  name: "Diaper",
  redirect: "/diaper/baby",
  children: [
    {
      path: "baby",
      name: "Baby Diaper Events",
      components: { default: DiaperBabyDashboard }
    },
    {
      path: "old",
      name: "Old Diaper Events",
      components: { default: DiaperOldDashboard }
    }
  ]
};

// Log
let logMenu = {
  path: "/log",
  component: DashboardLayout,
  name: "Log",
  redirect: "/log",
  children: [
    {
      path: "/log",
      name: "Log Reports",
      components: { default: Log },
    },
  ],
};
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
const routes = [
  deviceMenu,
  userMenu,
  userControl,
  authPages,
  diaperMenu,
  logMenu,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        components: {
          default: Dashboard
        }
      }
    ]
  }
];

export default routes;
