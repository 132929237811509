import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";

// router setup
import routes from "./routes/routes";

import store from "./store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(Loading, {
  canCancel: false,
  color: "#000000",
  loader: "dots", //spinner/dots/bars
  width: 50,
  height: 50,
  backgroundColor: "#ffffff",
  isFullPage: false,
  opacity: 0.8
});
// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueReCaptcha, { 
  siteKey: "6LeCrb8gAAAAAMpWP6BabWb0pjr7DC-oW13FlKLt",
  loaderOptions: {
    autoHideBadge: true,
    useRecaptchaNet: true,
  }
});
Vue.component("loading", Loading);
Vue.filter("formatDateYYYYMMDDHHMMSS", function(value) {
  if (value) {
    return moment(value).format("YYYY/MM/DD HH:mm:ss");
  }
});
// configure router
const router = new VueRouter({
  mode: "history",
  routes, // short for routes: routes
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active"
});

// global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  store,
  router
});
