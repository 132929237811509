<template>
  <ValidationObserver ref="data_form">
    <md-button class="md-default" @click="onBack()" v-if="tab_index !== '0'"
      >返回</md-button
    >
    <form @submit.prevent="validate">
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item  md-size-100">
              <ValidationProvider
                name="名稱"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[
                    { 'md-error': failed },
                    { 'md-valid': passed },
                    { 'md-form-group': true }
                  ]"
                >
                  <label>名稱 {{ errors[0] }}</label>
                  <md-input v-model="model.display_name" type="text"></md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>

            <div class="md-layout-item  md-size-30">
              <md-field>
                <label>Room Type</label>
                <md-input
                  v-model="model.room_type"
                  type="number"
                  readonly
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item  md-size-30">
              <md-field>
                <label>Home</label>
                <md-input
                  v-value="model.home_id ? model.home_id.display_name : ``"
                  type="text"
                  readonly
                ></md-input>
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card-actions>
        <md-button
          class="md-success"
          @click="onAdd()"
          v-show="isNew"
          v-if="has_permission(`add_${permission_name}`)"
          >新增</md-button
        >
        <md-button
          class="md-warning"
          @click="onEdit()"
          v-show="!isNew"
          v-if="has_permission(`change_${permission_name}`)"
          >儲存</md-button
        >
        <md-button
          class="md-danger"
          @click="onDelete()"
          v-show="!isNew"
          v-if="has_permission(`delete_${permission_name}`)"
          >刪除</md-button
        >
      </md-card-actions>
    </form>
  </ValidationObserver>
</template>
<script>
import Swal from "sweetalert2";
import request from "@/utils/requestGolang";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { localize } from "vee-validate";
import zh_TW from "vee-validate/dist/locale/zh_TW.json";

localize("zh_TW", zh_TW);
extend("required", required);
extend("email", email);

export default {
  components: {},
  created: async function() {
    this.isLoading = false;
    if (this.select_model) this.model = this.select_model;
    this.key_id = this.model.room_id;
  },

  data: function() {
    let now = new Date();
    return {
      isLoading: false,
      info: this.$store.state.info,
      brand_list: this.$store.state.brand,
      url_name: "room_list",
      permission_name: "room",
      model: {
        display_name: "",
        room_id: "",
        room_type: 0,
        home_id: {
          display_name: ""
        }
      },
      key_id: ""
    };
  },
  props: {
    select_model: Object,
    tab_index: {
      type: String,
      default: "0"
    }
  },

  computed: {
    isNew() {
      return this.key_id.length === 0;
    }
  },
  methods: {
    validate() {
      return this.$refs.data_form.validate().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
    onBack() {
      this.$emit("on-back", this.tab_index);
    },
    // todo permission

    async onAdd() {
      let isvalided = await this.validate();
      if (isvalided) {
        try {
          let pushData = { ...this.model };
          delete pushData.user_id;
          let { data } = await request.post(`/${this.url_name}/`, {
            ...this.model
          });
          this.model.user_id = "have";
          this.$emit("on-back", this.tab_index);
          this.$notify({
            timeout: 2500,
            message: "創建成功",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success"
          });
        } catch (error) {
          this.$notify({
            timeout: 2500,
            message: "error",
            icon: "add_alert",
            horizontalAlign: "center",
            verticalAlign: "bottom",
            type: "success"
          });
        }
      }
    },
    async onEdit() {
      try {
        let { data } = await request.put(`/${this.url_name}/${this.key_id}/`, {
          ...this.model
        });
        this.$notify({
          timeout: 2500,
          message: "修改成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
      }
    },
    async onDelete() {
      Swal.fire({
        title: "確定要刪除嗎?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        buttonsStyling: false
      }).then(async result => {
        if (result.dismiss) {
          return;
        }
        await this.onDeleteApi();
        this.$emit("on-back", this.tab_index);
      });
    },
    async onDeleteApi() {
      try {
        let { data } = await request.delete(
          `/${this.url_name}/${this.key_id}/`,
          {}
        );
        this.$notify({
          timeout: 2500,
          message: "刪除成功",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
        this.$emit("on-back", this.tab_index);
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: "error",
          icon: "add_alert",
          horizontalAlign: "center",
          verticalAlign: "bottom",
          type: "success"
        });
      }
    }
  },
  mounted() {},
  watch: {
    async query_url(value) {
      this.requestData(value);
    }
  }
};
</script>

<style lang="css" scoped>
.error.md-icon {
  right: 20px;
}
.success.md-icon {
  right: 20px;
}
</style>
