export const perm = Object.freeze({
  ChangePermission: 6,
  ViewPermission: 8,
  ChangeUsers: 66,
  ViewUsers: 68,
  AddBrand: 81,
  ChangeBrand: 82,
  DeleteBrand: 83,
  ViewBrand: 84,
  AddProduct: 85,
  ChangeProduct: 86,
  DeleteProduct: 87,
  ViewProduct: 88,
  AddModel: 93,
  ChangeModel: 94,
  DeleteModel: 95,
  ViewModel: 96,
  AddFirmware: 105,
  ChangeFirmware: 106,
  DeleteFirmware: 107,
  ViewFirmware: 108,
  AddDevice: 109,
  ChangeDevice: 110,
  DeleteDevice: 111,
  ViewDevice: 112,
  AddHome: 117,
  ChangeHome: 118,
  DeleteHome: 119,
  ViewHome: 120,
  AddRoom: 125,
  ChangeRoom: 126,
  DeleteRoom: 127,
  ViewRoom: 128,
  AddZone: 129,
  ChangeZone: 130,
  DeleteZone: 131,
  ViewZone: 132,
  ViewBaby: 209,
  ViewOld: 213,
});

function HasPermission(info, pe) {
  if (info.is_super_user) {
    return true;
  }
  for (const p of info.Perm) {
    if (p.permission_id == pe) {
      return true;
    }
  }
  return false;
}

export default HasPermission;
